import useComponentDidUpdate from 'app/hooks/useComponentDidUpdate';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useComponentDidUpdate(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <> </>;
};

export default ScrollToTop;
