import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Colors from 'app/constants/Colors';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

export interface ButtonStylesProps {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'destructive' | 'text';
  isLoading?: boolean;
  as?: any;
}

const Container = styled.button<ButtonStylesProps>`
  min-width: ${convertPixelToRem(150)};
  width: ${convertPixelToRem(150)};
  min-height: ${convertPixelToRem(50)};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  ${(props) =>
    props.isLoading &&
    css`
      opacity: 0.9;
      pointer-events: none;
      cursor: not-allowed;
    `};

  ${(props) => {
    switch (props.variant) {
      case 'primary':
        return css`
          background-color: ${Colors.yellow};
          color: ${Colors.black};
        `;
      case 'secondary':
        return css`
          background-color: ${Colors.blue};
          color: ${Colors.white};
        `;

      case 'tertiary':
        return css`
          background-color: ${Colors.white};
          color: ${Colors.blue};
          border: solid 1px ${Colors.blue};
        `;

      case 'destructive':
        return css`
          background-color: ${Colors.red};
          color: ${Colors.white};
        `;

      case 'text':
        return css`
          width: max-content;
          background: transparent;
          min-width: unset;
          min-height: unset;
        `;
      default:
        break;
    }
  }}
`;

const ButtonStyles = { Container };

export default ButtonStyles;
