import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'app/components/ui/Button';
import Input from 'app/components/ui/Input';
import LoginStyles from './styles';
import AdminLoginContext, {
  useAdminLoginContext,
} from 'app/context/Admin/Login/LoginContext';
import { LoginPayload } from 'app/services/firebase/types';

type FormData = LoginPayload;

const Schema = yup.object().shape({
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('Kindly enter admin email address'),
  password: yup.string().required('Kindly enter admin password'),
});

const Login = () => {
  const contextValues = useAdminLoginContext();
  const history = useHistory();

  const { control, handleSubmit, errors } = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(Schema),
  });

  const submit = (data: FormData) => {
    contextValues.submit?.(data);
  };

  useEffect(() => {
    if (contextValues.status === 'submitted') {
      history.push('/admin/news');
    }
  }, [contextValues.status, history]);

  return (
    <AdminLoginContext.Provider value={contextValues}>
      <LoginStyles.Container>
        <LoginStyles.Form onSubmit={handleSubmit(submit)}>
          <Controller
            as={<Input label="Email" errorMessage={errors.email?.message} />}
            control={control}
            name="email"
          />
          <Controller
            as={
              <Input
                label="Password"
                type="password"
                errorMessage={errors.password?.message}
              />
            }
            control={control}
            name="password"
          />
          <Button
            type="submit"
            variant="secondary"
            isLoading={contextValues.status === 'submitting'}
          >
            Login
          </Button>
        </LoginStyles.Form>
      </LoginStyles.Container>
    </AdminLoginContext.Provider>
  );
};

export default Login;
