import { createContext, useState } from 'react';
import { EmailServices } from 'app/services';
import { StatusType } from '../types';
import { SendApplicationFormPayload } from 'app/services/email/types';
import { toast } from 'react-toastify';
import { parseEmailJSError } from 'app/utils/parseError';

interface AcademyContextProps {
  status: StatusType;
  submit: (_: SendApplicationFormPayload) => void;
}

const AcademyContext = createContext<AcademyContextProps>({
  status: 'idle',
  submit: (_: SendApplicationFormPayload) => {},
});

export const useAcademyContext = (): AcademyContextProps => {
  const [status, setStatus] = useState<AcademyContextProps['status']>('idle');

  return {
    status,
    submit: async (payload: SendApplicationFormPayload) => {
      setStatus('submitting');
      try {
        await EmailServices.sendApplicationForm(payload);
        setStatus('submitted');
        toast.success('Request sent successfully');
      } catch (error) {
        const errorMessage = parseEmailJSError(error);
        toast.error(errorMessage);
        setStatus('error');
      }
    },
  };
};

export default AcademyContext;
