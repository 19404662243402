import { ButtonHTMLAttributes, FC, HTMLAttributes } from 'react';
import Loader from '../Loader';
import ButtonStyles, { ButtonStylesProps } from './styles';

interface Props
  extends ButtonStylesProps,
    HTMLAttributes<HTMLButtonElement>,
    ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: FC<Props> = ({ children, variant = 'primary', ...props }) => {
  return (
    <ButtonStyles.Container {...props} variant={variant}>
      {props.isLoading ? <Loader /> : children}
    </ButtonStyles.Container>
  );
};

export default Button;
