import Button from 'app/components/ui/Button';
import Text from 'app/components/ui/Text';
import IntroductionImage from 'app/assets/images/home-banner.png';
import JustForYouImage from 'app/assets/images/just-for-you.png';
import SHUImage from 'app/assets/images/shu-image.png';
import SHBImage from 'app/assets/images/shb-image.png';
import SHPImage from 'app/assets/images/shp-image.png';
import { ReactComponent as CheckedIcon } from 'app/assets/icons/checked.svg';
import {
  Introduction,
  WhatWeDo,
  HomeUtils,
  JustForYou,
  Academy,
  SHU,
  SHB,
  SHP,
  PackageTypeContainer,
  PackageTypeBadge,
} from './styles';
import { whatWeDoGrid } from '../../data/whatWeDoGrid';
import { useHistory } from 'react-router-dom';
import { ProductType } from 'app/context/RequestService/RequestServiceContext';
import { Helmet } from 'react-helmet';

const justForYouOfferDescription = [
  'LED Bulbs',
  'Clipper',
  'Fan',
  'Phones',
  'Blender',
  'Laptop',
];

const SHUDescription = [
  'Inverter AC',
  'Bulbs',
  'Deep Freezer & Fridge',
  'LED TVs',
  'Pumping Machine',
  'Fans',
  'Blender & Clipper',
  'Home Theater',
  'Phones & Laptop',
  'Washing Machine',
];

const SHBDescription = [
  'LED Bulbs',
  'Blender',
  'Fan',
  'LED TV',
  'Decoder',
  'Laptop',
  'Phones',
  'Clipper',
];

const SHPDescription = [
  'LED bulbs',
  'Blender',
  'Fan',
  'Home Theater',
  'Laptop',
  'Phones',
  'Clipper',
  'Inverter AC',
];

const Home = () => {
  const history = useHistory();

  const handleRequest = (product: ProductType) => {
    history.push(`/request-a-service?product=${product}`);
  };

  const navigateToAcademyPage = () => {
    history.push('/academy');
  };

  const navigateToAboutPage = () => {
    history.push('/about');
  };

  const navigateToServicePage = () => {
    history.push('/service');
  };

  return (
    <>
      <Helmet>
        <title>Sinnot Technologies</title>
      </Helmet>
      <Introduction.Container className="pad">
        <Introduction.Text>
          {/* @ts-ignore */}
          <HomeUtils.Title as="h3">We @ SINNOT Technologies</HomeUtils.Title>
          <Text>
            Sinnot Technologies Limited is a clean energy technology company
            dedicated to solving energy poverty in Africa through rendering
            unbeatable solar solution services (Micro or Mini grid), producing
            affordable quality local inverters, advancing on innovation,
            creativity, researches and training on various clean energy
            technology that provide solutions to energy challenges and climate
            change in Africa.
          </Text>
          <Button variant="secondary" onClick={navigateToAboutPage}>
            Read More
          </Button>
        </Introduction.Text>
        <Introduction.Image src={IntroductionImage} />
      </Introduction.Container>
      <WhatWeDo.Container>
        {/* @ts-ignore */}
        <HomeUtils.Title as="h3">What We Do</HomeUtils.Title>
        <div className="grid-container">
          {whatWeDoGrid.map(({ title, image }) => {
            return (
              <div className="grid-item" key={image}>
                <img src={image} alt="Sinnot technologies" />
                {title}
              </div>
            );
          })}
        </div>
        <Button variant="secondary" onClick={navigateToServicePage}>
          {' '}
          Read More
        </Button>
      </WhatWeDo.Container>
      <JustForYou.Container>
        <div className="badge">
          <Text> Our </Text>
          <Text className="tag"> 'Just-For-You'</Text>
          <Text> Offers</Text>
        </div>
        <div className="flex">
          <JustForYou.Image src={JustForYouImage} />
          <JustForYou.Description>
            <div className="title">
              {/* @ts-ignore */}

              <HomeUtils.Title as="h3">SHM</HomeUtils.Title>
              <Text as="p"> (Sinnot Home Mini)</Text>
            </div>
            <PackageTypeContainer>
              <PackageTypeBadge color="yellow">Lite</PackageTypeBadge>
              <PackageTypeBadge color="darkyellow">Pro</PackageTypeBadge>
              <PackageTypeBadge color="orange">Max</PackageTypeBadge>
            </PackageTypeContainer>

            <div className="grid">
              {justForYouOfferDescription.map((label) => {
                return (
                  <div className="grid-item" key={label}>
                    <CheckedIcon className="grid-item-icon" />
                    <Text> {label} </Text>
                  </div>
                );
              })}
            </div>
            <div></div>
            <Button variant="tertiary" onClick={() => handleRequest('SHM')}>
              Place a Request
            </Button>
          </JustForYou.Description>
        </div>
      </JustForYou.Container>
      <SHB.Container>
        <SHB.Description>
          <div className="title">
            {/* @ts-ignore */}
            <HomeUtils.Title as="h3">SHB</HomeUtils.Title>
            <Text as="p"> (Sinnot Home Basic) </Text>
          </div>
          <PackageTypeContainer>
            <PackageTypeBadge color="yellow">Lite</PackageTypeBadge>
            <PackageTypeBadge color="darkyellow">Pro</PackageTypeBadge>
            <PackageTypeBadge color="orange">Max</PackageTypeBadge>
          </PackageTypeContainer>

          <div className="grid">
            {SHBDescription.map((label) => {
              return (
                <div className="grid-item" key={label}>
                  <CheckedIcon className="grid-item-icon" />
                  <Text> {label} </Text>
                </div>
              );
            })}
          </div>
          <div></div>
          <Button onClick={() => handleRequest('SHB')}> Place a Request</Button>
        </SHB.Description>
        <SHB.Image src={SHBImage} />
      </SHB.Container>
      <SHP.Container>
        <div className="flex">
          <SHP.Image src={SHPImage} />
          <SHP.Description>
            <div className="title">
              {/* @ts-ignore */}

              <HomeUtils.Title as="h3">SHP</HomeUtils.Title>
              <Text as="p"> (Sinnot Home Premium)</Text>
            </div>
            <PackageTypeContainer>
              <PackageTypeBadge color="yellow">Lite</PackageTypeBadge>
              <PackageTypeBadge color="darkyellow">Pro</PackageTypeBadge>
              <PackageTypeBadge color="orange">Max</PackageTypeBadge>
            </PackageTypeContainer>

            <div className="grid">
              {SHPDescription.map((label) => {
                return (
                  <div className="grid-item" key={label}>
                    <CheckedIcon className="grid-item-icon" />
                    <Text> {label} </Text>
                  </div>
                );
              })}
            </div>
            <div></div>
            <Button onClick={() => handleRequest('SHP')} variant="tertiary">
              Place a Request
            </Button>
          </SHP.Description>
        </div>
      </SHP.Container>
      <SHU.Container>
        <SHU.Description>
          <div className="title">
            {/* @ts-ignore */}
            <HomeUtils.Title as="h3">SHU</HomeUtils.Title>
            <Text as="p"> (Sinnot Home Ultimate) </Text>
          </div>
          <PackageTypeContainer>
            <PackageTypeBadge color="yellow">Lite</PackageTypeBadge>
            <PackageTypeBadge color="darkyellow">Pro</PackageTypeBadge>
            <PackageTypeBadge color="orange">Max</PackageTypeBadge>
          </PackageTypeContainer>

          <div className="grid">
            {SHUDescription.map((label) => {
              return (
                <div className="grid-item" key={label}>
                  <CheckedIcon className="grid-item-icon" />
                  <Text> {label} </Text>
                </div>
              );
            })}
          </div>
          <div></div>
          <Button onClick={() => handleRequest('SHU')}> Place a Request</Button>
        </SHU.Description>
        <SHU.Image src={SHUImage} />
      </SHU.Container>
      <Academy.Container>
        <div className="badge">
          <Text>solar-inverter academy </Text>
        </div>
        <div>
          <Text as="p">
            Ready to launch a fulfilling career in solar energy? We’ve got you
            covered! You’ll learn how to properly design & install solar
            photovoltaic power systems of any scale in an ultra-intensive class
            taught by seasond veterans and attended by super exciting, ultra
            inquisitive folks like you!
          </Text>
        </div>
        <div>
          <Button onClick={navigateToAcademyPage}>Learn more</Button>
        </div>
      </Academy.Container>
    </>
  );
};

export default Home;
