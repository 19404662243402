import { FC } from 'react';
import Loader, { LoaderProps } from '../Loader/Loader';
import LoaderBoxStyles, { LoaderBoxStylesProps } from './styles';

interface Props extends LoaderProps, LoaderBoxStylesProps {}

const LoaderBox: FC<Props> = ({ height, ...props }) => {
  return (
    <LoaderBoxStyles.Container height={height}>
      <Loader size={30} color="blue" {...props} />
    </LoaderBoxStyles.Container>
  );
};

export default LoaderBox;
