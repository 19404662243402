import AdminLoginContext, {
  useAdminLoginContext,
} from 'app/context/Admin/Login/LoginContext';
import { RouteURLs } from 'app/routes';
import { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Login from './Login';
import News from './News';
import Gallery from './Gallery';

const Admin = {
  Login,
  News,
  Gallery,
};

export const AdminOverall: FC = ({ children }) => {
  const contextValues = useAdminLoginContext();
  const history = useHistory();

  const shouldRouteToGuestRoute = useCallback(() => {
    const pathname = history.location.pathname as RouteURLs;
    if (!Boolean(contextValues.token) && pathname !== '/admin/login')
      return true;
    return false;
  }, [contextValues.token, history.location.pathname]);

  useEffect(() => {
    if (shouldRouteToGuestRoute()) {
      history.push('/admin/login');
    }
  }, [history, shouldRouteToGuestRoute]);

  return (
    <AdminLoginContext.Provider value={contextValues}>
      {children}
    </AdminLoginContext.Provider>
  );
};

export default Admin;
