import styled from '@emotion/styled';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const Form = styled.form`
  width: 50%;
  max-width: ${convertPixelToRem(400)};
  height: max-content;
  display: grid;
  row-gap: ${convertPixelToRem(15)};
  padding: ${convertPixelToRem(30)};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

  button {
    margin: auto;
    margin-top: ${convertPixelToRem(20)};
  }
`;

const LoginStyles = {
  Container,
  Form,
};

export default LoginStyles;
