import React, { TextareaHTMLAttributes } from 'react';
import COLORS from 'app/constants/Colors';
import Text from '../Text';

import TextAreaStyles, { TextAreaProps } from './styles';

interface Props extends TextAreaProps, TextareaHTMLAttributes<any> {
  label?: string;
  errorMessage?: string;
}

const TextArea: React.FC<Props> = React.forwardRef(
  ({ label, errorMessage, required, ...rest }, ref) => {
    return (
      <TextAreaStyles.Container>
        {label && (
          <Text as="label">
            {label} {required && <Text color="red200"> * </Text>}
          </Text>
        )}
        {/*@ts-ignore */}
        <TextAreaStyles.Element {...rest} ref={ref} error={errorMessage} />
        {errorMessage && (
          <Text color={COLORS.red200} as="span" size={14}>
            {errorMessage}
          </Text>
        )}
      </TextAreaStyles.Container>
    );
  }
);

export default TextArea;
