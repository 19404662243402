import { useContext, useEffect } from 'react';
import AdminNewsContext from 'app/context/Admin/News/AdminNewsContext';
import AdminNewsStyles from './styles';
import Text from 'app/components/ui/Text';
import LoaderBox from 'app/components/ui/LoaderBox';
import { useRouteMatch } from 'react-router-dom';
import { convertHMTLToString } from 'app/utils/helpers';
import Pagination from 'app/components/ui/Pagination';
import usePagination from 'app/hooks/usePagination';

const News = () => {
  const contextValues = useContext(AdminNewsContext);
  const { url } = useRouteMatch();
  const {
    numberOfPages,
    handlePageChange,
    currentPage,
    currentDisplayedData,
  } = usePagination(contextValues.data || {}, 4);

  const news = Object.keys(currentDisplayedData || {});

  const determineDisplayedContext = () => {
    if (
      (contextValues.status === 'fetching' ||
        contextValues.status === 'idle') &&
      !contextValues.data
    )
      return <LoaderBox />;
    else if (news.length === 0) {
      return <p>Empty News</p>;
    }

    return (
      <>
        <AdminNewsStyles.NewsGrid>
          {Object.values(currentDisplayedData || {}).map(
            ({ id, title, content, previewImage }) => {
              return (
                <AdminNewsStyles.NewsGridItem to={`${url}/view/${id}`} key={id}>
                  <img src={previewImage} alt={title} />
                  <section>
                    <Text as="h3"> {title} </Text>
                    <Text as="p">{convertHMTLToString(content || '')}</Text>
                  </section>
                </AdminNewsStyles.NewsGridItem>
              );
            }
          )}
        </AdminNewsStyles.NewsGrid>
        <Pagination
          pageCount={numberOfPages}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          className="pagination"
          onPageChange={handlePageChange}
          forcePage={currentPage}
        />
      </>
    );
  };

  useEffect(() => {
    contextValues.fetchNews({ endAt: '5', startAt: '1' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <> {determineDisplayedContext()} </>;
};

export default News;
