import styled from '@emotion/styled';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

export interface LoaderBoxStylesProps {
  height?: number;
}

const Container = styled.div<LoaderBoxStylesProps>`
  height: ${(props) =>
    props.height ? convertPixelToRem(props.height) : '100vh'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderBoxStyles = {
  Container,
};

export default LoaderBoxStyles;
