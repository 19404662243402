import Pagination from 'app/components/ui/Pagination';
import Text from 'app/components/ui/Text';
import usePagination from 'app/hooks/usePagination';
import GalleryStyles from './styles';
import ClientGalleryContext, {
  useClientGalleryContext,
} from 'app/context/ClientGallery/ClientGalleryContext';
import { useEffect } from 'react';
import LoaderBox from 'app/components/ui/LoaderBox';
import { Helmet } from 'react-helmet';

const ClientGallery = () => {
  const contextValues = useClientGalleryContext();
  const {
    currentDisplayedData,
    numberOfPages,
    handlePageChange,
    currentPage,
  } = usePagination(contextValues.data || {}, 6);

  useEffect(() => {
    contextValues.fetchGallery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    (contextValues.status === 'idle' || contextValues.status === 'fetching') &&
    !contextValues.data
  )
    return <LoaderBox height={200} />;

  return (
    <>
      <Helmet>
        <title>Gallery | Sinnot Technologies</title>
      </Helmet>
      <ClientGalleryContext.Provider value={contextValues}>
        <GalleryStyles.Container className="pad">
          <Text align="center" size={25} as="h3" weight={700}>
            SINNOT IMAGE GALLERY
          </Text>
          <GalleryStyles.Grid>
            {Object.keys(currentDisplayedData || {}).map((id) => {
              const correspondingData = currentDisplayedData?.[id];
              return (
                <GalleryStyles.GridItem key={id}>
                  <img
                    src={correspondingData?.imageURL}
                    alt={correspondingData?.caption}
                  />
                  <Text align="center" size={18}>
                    {correspondingData?.caption}
                  </Text>
                </GalleryStyles.GridItem>
              );
            })}
          </GalleryStyles.Grid>
          <Pagination
            pageCount={numberOfPages}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            className="pagination"
            onPageChange={handlePageChange}
            forcePage={currentPage}
          />
        </GalleryStyles.Container>
      </ClientGalleryContext.Provider>
    </>
  );
};

export default ClientGallery;
