import Button from 'app/components/ui/Button';
import AdminLoginContext from 'app/context/Admin/Login/LoginContext';
import { FC, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import GeneralAdminStyles from './styles';

const GeneralAdmin: FC<{}> = ({ children }) => {
  const history = useHistory();
  const { status, logout } = useContext(AdminLoginContext);

  const navigateToAddNews = () => {
    history.push(`/admin/news/add`);
  };

  const navigateToGallery = () => {
    history.push('/admin/gallery');
  };

  const navigateToUploadGallery = () => {
    history.push('/admin/gallery/upload-gallery');
  };

  useEffect(() => {
    if (status === 'loaded') {
      history.push('/admin');
    }
  }, [status, history]);

  return (
    <>
      <GeneralAdminStyles.Header>
        <Link to="/admin/news">Admin News</Link>
        <div className="button-container">
          <Button variant="secondary" onClick={navigateToAddNews}>
            Add News
          </Button>
          <Button onClick={navigateToGallery}> Manage Gallery </Button>
          <Button variant="tertiary" onClick={navigateToUploadGallery}>
            Upload Gallery
          </Button>
          <Button
            variant="destructive"
            onClick={logout}
            isLoading={status === 'loading'}
          >
            Log out
          </Button>
        </div>
      </GeneralAdminStyles.Header>
      <GeneralAdminStyles.Body>{children}</GeneralAdminStyles.Body>
    </>
  );
};

export default GeneralAdmin;
