import AdminNewsContext, {
  useAdminNewsContext,
} from 'app/context/Admin/News/AdminNewsContext';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import News from './News';
import ProcessNews from './ProcessNews';
import ViewAdminNews from './ViewAdminNews';

const NewsRoutes = () => {
  const { url } = useRouteMatch();
  const contextValues = useAdminNewsContext();

  return (
    <AdminNewsContext.Provider value={contextValues}>
      <Switch>
        <Route path={url} exact component={News} />
        <Route path={`${url}/view/:id`} exact component={ViewAdminNews} />
        <Route path={`${url}/add`} exact component={ProcessNews} />
        <Route path={`${url}/edit/:id`} exact component={ProcessNews} />
      </Switch>
    </AdminNewsContext.Provider>
  );
};

export default NewsRoutes;
