const createActionType = (key: string) => {
  const convertToUpperCase = (key: string): string => {
    return key.toUpperCase();
  };
  return {
    default: convertToUpperCase(key),
    pending: convertToUpperCase(`${key}_pending`),
    fulfilled: convertToUpperCase(`${key}_fulfilled`),
    rejected: convertToUpperCase(`${key}_rejected`),
  };
};

export default createActionType;
