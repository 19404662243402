import { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import COLORS from 'app/constants/Colors';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

export interface TextProps extends HTMLAttributes<any> {
  lineHeight?: number;
  as?: any;
  weight?: number;
  size?: number;
  color?: string;
  align?: 'center' | 'left' | 'right';
}

export const TextStyle = styled.span<TextProps>`
  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `};
  ${(props) =>
    props.weight &&
    css`
      font-weight: ${props.weight};
    `};
  ${(props) =>
    props.size &&
    css`
      font-size: ${convertPixelToRem(props.size)};
    `};
  ${(props) =>
    props.color &&
    css`
      color: ${COLORS[props.color] || props.color};
    `};
  ${(props) =>
    props.lineHeight &&
    css`
      line-height: ${convertPixelToRem(props.lineHeight)};
    `}
`;
