import AdminGalleryContext, {
  useAdminGalleryContext,
} from 'app/context/Admin/Gallery/AdminGalleryContext';
import AdminGallery from './Gallery';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import UploadGallery from './UploadGallery';

const AdminGalleryRoutes = () => {
  const { url } = useRouteMatch();

  const contextValues = useAdminGalleryContext();

  return (
    <AdminGalleryContext.Provider value={contextValues}>
      <Switch>
        <Route path={url} exact component={AdminGallery} />
        <Route path={`${url}/upload-gallery`} exact component={UploadGallery} />
      </Switch>
    </AdminGalleryContext.Provider>
  );
};

export default AdminGalleryRoutes;
