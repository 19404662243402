import { createContext, useReducer } from 'react';

import { FirebaseServices } from 'app/services';
import { LoginPayload } from 'app/services/firebase/types';
import { GenericAction } from '../../types';
import {
  AdminLoginContextProps,
  AdminLoginContextPropsWithoutSubmit,
  updateAdminLogoutStatus,
  updateAdminLoginStatus,
} from './types';
import { toast } from 'react-toastify';

const INITIAL_VALUE: AdminLoginContextPropsWithoutSubmit = {
  status: 'idle',
  token: null,
};

const AdminLoginContext = createContext<AdminLoginContextProps>({
  ...INITIAL_VALUE,
  submit: null,
  logout: () => {},
});

const reducer = (
  state: AdminLoginContextPropsWithoutSubmit,
  action: GenericAction
): AdminLoginContextPropsWithoutSubmit => {
  switch (action.type) {
    case updateAdminLoginStatus.pending:
      return {
        ...state,
        status: 'submitting',
      };

    case updateAdminLoginStatus.fulfilled:
      return {
        ...state,
        status: 'submitted',
        token: action.payload,
      };

    case updateAdminLoginStatus.rejected:
    case updateAdminLogoutStatus.rejected:
      return {
        ...state,
        status: 'error',
      };

    case updateAdminLogoutStatus.pending:
      return {
        ...state,
        status: 'loading',
      };

    case updateAdminLogoutStatus.fulfilled:
      return {
        ...state,
        status: 'loaded',
        token: null,
      };

    default:
      return state;
  }
};

export const useAdminLoginContext = (): AdminLoginContextProps => {
  const [state, dispatch] = useReducer(reducer, INITIAL_VALUE);

  return {
    ...state,
    submit: async (payload: LoginPayload) => {
      try {
        dispatch({ type: updateAdminLoginStatus.pending });
        await FirebaseServices.login(payload);
        const token = await FirebaseServices.getIdToken();
        dispatch({ type: updateAdminLoginStatus.fulfilled, payload: token });
      } catch (error) {
        const errorMessage = error.message;
        toast.error(errorMessage);
        dispatch({
          type: updateAdminLoginStatus.rejected,
          payload: errorMessage,
        });
      }
    },
    logout: async () => {
      try {
        dispatch({ type: updateAdminLogoutStatus.pending });
        await FirebaseServices.logout();
        dispatch({ type: updateAdminLogoutStatus.fulfilled });
      } catch (error) {
        const errorMessage = error.message;
        toast.error(errorMessage);
        dispatch({
          type: updateAdminLogoutStatus.rejected,
          payload: errorMessage,
        });
      }
    },
  };
};

export default AdminLoginContext;
