import { FC, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ReactComponent as CancelIcon } from 'app/assets/icons/cancel.svg';
import { ApplicationFormStyles } from '../styles';
import Text from 'app/components/ui/Text';
import Input from 'app/components/ui/Input';
import Select, { SelectOptionsProps } from 'app/components/ui/Select';
import TextArea from 'app/components/ui/TextArea';
import Button from 'app/components/ui/Button';
import { AcademyDataProps } from 'app/data/academy';
import useComponentDidUpdate from 'app/hooks/useComponentDidUpdate';
import AcademyContext, {
  useAcademyContext,
} from 'app/context/AcademyApplication/AcademyContext';
import { SendApplicationFormPayload } from 'app/services/email/types';

interface Props {
  isOpen: boolean;
  content: AcademyDataProps | null;
  closeModal: () => void;
}

type FormData = SendApplicationFormPayload;

const Schema = yup.object().shape({
  email: yup
    .string()
    .required('Kindly fill in your email address')
    .email('Kindly fill in a valid email'),
  firstName: yup.string().required('Kindly fill in your first name'),
  surname: yup.string().required('Kindly fill in your surname'),
  stateOfResidence: yup
    .string()
    .required('Kindly fill in your state of residence'),
});

Modal.setAppElement('#root');

const howDidYouHearAboutUsOption: SelectOptionsProps = [
  {
    label: 'Social Media',
    value: 'Social Media',
  },
  {
    label: 'Blog Post',
    value: 'Blog Post',
  },
  {
    label: 'Newspaper',
    value: 'Newspaper',
  },
  {
    label: 'A friend',
    value: 'A friend',
  },
];

const ApplicationForm: FC<Props> = (props) => {
  const [shouldClickAwayFire, setshouldClickAwayFire] =
    useState<boolean>(false);
  const academyContextValues = useAcademyContext();

  const { control, errors, handleSubmit, reset } = useForm<FormData>({
    defaultValues: {
      email: '',
      firstName: '',
      surname: '',
      phoneNumber: '',
      gender: 'M',
      howUserHeard: 'Social Media',
      mainInterestInTheProgram: '',
      occupationEmployer: '',
      stateOfResidence: '',
      otherRelevantInformation: '',
    },
    resolver: yupResolver(Schema),
  });

  const handleClickAway = () => {
    if (shouldClickAwayFire) {
      props.closeModal();
    } else {
      setshouldClickAwayFire(true);
    }
  };

  const submit = (data: FormData) => {
    academyContextValues.submit(data);
  };

  useComponentDidUpdate(() => {
    setshouldClickAwayFire(shouldClickAwayFire);
  }, [shouldClickAwayFire]);

  useEffect(() => {
    if (academyContextValues.status === 'submitted') {
      reset();
    }
  }, [academyContextValues.status, reset]);

  return (
    <AcademyContext.Provider value={academyContextValues}>
      <ApplicationFormStyles.Modal
        isOpen={props.isOpen}
        contentLabel={props.content?.title}
      >
        <ApplicationFormStyles.Container
          onClickAway={handleClickAway}
          as="section"
        >
          <Button className="icon" variant="text" onClick={props.closeModal}>
            <CancelIcon />
          </Button>
          <ApplicationFormStyles.Title>
            <img src={props.content?.image} alt={props.content?.title} />
            <Text as="h2" color="yellow100" weight={600}>
              {props.content?.title} TRAINING APPLICATION FORM
            </Text>
            <Text as="p" color="white">
              Kindly fill the form below and we'll contact you immediately.
            </Text>
          </ApplicationFormStyles.Title>
          <ApplicationFormStyles.Form
            onSubmit={handleSubmit(submit)}
            id="application-form"
          >
            <Controller
              as={
                <Input
                  label="First Name"
                  placeholder="First Name"
                  required
                  errorMessage={errors.firstName?.message}
                />
              }
              control={control}
              name="firstName"
            />
            <Controller
              as={
                <Input
                  label="Surname"
                  placeholder="Surname"
                  required
                  errorMessage={errors.surname?.message}
                />
              }
              control={control}
              name="surname"
            />
            <Controller
              as={<Input label="Phone Number" placeholder="Phone Number" />}
              control={control}
              required
              name="phoneNumber"
            />
            <Controller
              as={
                <Input
                  label="Email Address"
                  required
                  placeholder="Email Address"
                  errorMessage={errors.email?.message}
                />
              }
              control={control}
              name="email"
            />
            <Controller
              as={
                <Input
                  label="Occupation / Employer"
                  placeholder="Occupation / Employer"
                />
              }
              control={control}
              name="occupationEmployer"
            />

            <Controller
              render={({ onChange, value, name }) => (
                <Select
                  label="Gender"
                  options={[
                    { label: 'Male', value: 'M' },
                    { label: 'Female', value: 'F' },
                  ]}
                  name={name}
                  onChange={onChange}
                  value={value}
                />
              )}
              control={control}
              name="gender"
            />
            <Controller
              as={
                <Input
                  label="State of Residence"
                  required
                  placeholder="State of Residence"
                  errorMessage={errors.stateOfResidence?.message}
                />
              }
              control={control}
              name="stateOfResidence"
            />

            <Controller
              render={({ ref: _, ...props }) => (
                <Select
                  label="How Did You Hear About The Training"
                  options={howDidYouHearAboutUsOption}
                  {...props}
                />
              )}
              control={control}
              name="howUserHeard"
            />
            <Controller
              as={
                <Input
                  label="Main Interest In The Program?"
                  placeholder="Main Interest In The Program?"
                />
              }
              control={control}
              name="mainInterestInTheProgram"
            />
            <Controller
              as={<TextArea label="Other Relevant Information" />}
              control={control}
              name="otherRelevantInformation"
            />
          </ApplicationFormStyles.Form>
          <Button
            form="application-form"
            type="submit"
            isLoading={academyContextValues.status === 'submitting'}
          >
            Apply
          </Button>
        </ApplicationFormStyles.Container>
      </ApplicationFormStyles.Modal>
    </AcademyContext.Provider>
  );
};

export default ApplicationForm;
