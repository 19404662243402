import styled from '@emotion/styled';
import Modal from 'react-modal';

import Colors from 'app/constants/Colors';
import media from 'app/utils/media';
import { convertPixelToRem } from 'app/utils/stylesHelpers';
import ClickAwayListener from 'react-click-away-listener';

const Container = styled.section`
  padding-top: ${convertPixelToRem(20)};
  padding-bottom: ${convertPixelToRem(30)};
  display: grid;
  row-gap: ${convertPixelToRem(35)};
`;

const CoursesContainer = styled.section`
  display: grid;
  row-gap: ${convertPixelToRem(35)};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  column-gap: ${convertPixelToRem(30)};
  row-gap: ${convertPixelToRem(30)};
  width: 80%;
  margin: auto;

  ${media.bigTablet`
    width : 90%;
    grid-template-columns : 100%;
  `}

  ${media.phone`
    width : 95%;
    grid-auto-flow : row;
    grid-template-columns: unset;

  `}
`;

const GridItem = styled.section`
  display: grid;
  row-gap: ${convertPixelToRem(60)};
  padding: ${convertPixelToRem(20)} ${convertPixelToRem(10)};
  justify-content: center;
  align-content: center;
  text-align: center;
  background-color: ${Colors.blue};
  min-height: ${convertPixelToRem(450)};

  div {
    display: grid;
    row-gap: ${convertPixelToRem(20)};
    place-items: center;

    img {
      height: ${convertPixelToRem(92)};
    }
  }

  h3 {
    color: ${Colors.yellow100};
    width: 80%;
    margin: auto;
  }

  p {
    color: ${Colors.white};
    width: 80%;
    margin: auto;
  }

  button {
    justify-self: center;
    font-weight: 500;
  }
`;

const ApplicationFormModal = styled(Modal)`
  background-color: rgba(0, 0, 0, 0.8);
  padding: ${convertPixelToRem(40)} 0;
  overflow-y: auto;
  max-height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ApplicationFormContainer = styled(ClickAwayListener)`
  display: grid;
  width: 70%;
  margin: auto;
  background-color: ${Colors.blue};
  padding: ${convertPixelToRem(70)} 15% ${convertPixelToRem(20)};
  position: relative;
  .icon {
    width: ${convertPixelToRem(50)};
    height: ${convertPixelToRem(50)};
    justify-self: flex-end;
    position: absolute;
    right: ${convertPixelToRem(25)};
    top: ${convertPixelToRem(15)};

    svg {
      width: 100%;
      height: ${convertPixelToRem(15)};
      fill: ${Colors.yellow100};
    }
  }

  button {
    margin: auto;
  }
`;

const ApplicationFormTitle = styled.header`
  display: grid;
  row-gap: ${convertPixelToRem(15)};

  * {
    text-align: center;
  }

  img {
    margin: auto;
  }
`;

const ApplicationForm = styled.form`
  display: grid;
  row-gap: ${convertPixelToRem(20)};
  padding: ${convertPixelToRem(45)} 0;

  input,
  select,
  textarea {
    border-color: ${Colors.yellow};
    background-color: ${Colors.white};
  }
  label {
    color: ${Colors.white};
  }
`;

const AcademyStyles = {
  Container,
  Grid,
  GridItem,
  CoursesContainer,
};

export const ApplicationFormStyles = {
  Modal: ApplicationFormModal,
  Container: ApplicationFormContainer,
  Title: ApplicationFormTitle,
  Form: ApplicationForm,
};

export default AcademyStyles;
