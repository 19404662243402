import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Button from 'app/components/ui/Button';
import Input from 'app/components/ui/Input';
import TextArea from 'app/components/ui/TextArea';
import { UploadGalleryPayload } from 'app/services/firebase/types';
import { UploadGalleryStyles } from './styles';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { getFileSizeInKB } from 'app/utils/helpers';
import { toast } from 'react-toastify';
import AdminGalleryContext from 'app/context/Admin/Gallery/AdminGalleryContext';
import { useHistory } from 'react-router-dom';

type FormData = Omit<UploadGalleryPayload, 'image'> & { image: string };

const Schema = yup.object().shape({
  caption: yup
    .string()
    .required('Kindly input gallery caption')
    .max(40, 'Gallery caption cannot be greater than 40 characters '),
  image: yup.string().required('Kindly upload news preview image'),
});

const UploadGallery = () => {
  const contextValues = useContext(AdminGalleryContext);
  const history = useHistory();

  const {
    control,
    handleSubmit,
    errors,
    setValue,
    setError,
  } = useForm<FormData>({
    defaultValues: {
      caption: '',
      image: '',
    },
    resolver: yupResolver(Schema),
  });

  const [file, setFile] = useState<File | null>();

  const handleImageChange = (event: ChangeEvent<any>) => {
    const files = event.target.files as FileList;
    const MAX_SIZE = 1000;
    if (getFileSizeInKB(files[0].size) > MAX_SIZE) {
      setError('image', {
        message: ` Image ${files[0].name} is too large. Upload a file with a size less than 1mb`,
      });
      toast.error(
        ` Image ${files[0].name} is too large. Upload a file with a size less than 1mb`
      );
    } else {
      setValue('image', 'uploaded', { shouldValidate: true });
      setFile(files[0]);
    }
  };

  const submit = (payload: FormData) => {
    file && contextValues.uploadGallery({ ...payload, image: file });
  };

  useEffect(() => {
    if (contextValues.status === 'submitted') {
      history.push('/admin/gallery');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValues.status]);

  return (
    <UploadGalleryStyles.Container onSubmit={handleSubmit(submit)}>
      <Controller
        as={<TextArea label="Caption" errorMessage={errors.caption?.message} />}
        control={control}
        name="caption"
      />
      <Controller
        render={() => (
          <Input
            type="file"
            label="File"
            errorMessage={errors.image?.message}
            onChange={handleImageChange}
          />
        )}
        control={control}
        name="image"
      />
      <Button
        variant="secondary"
        type="submit"
        isLoading={contextValues.status === 'submitting'}
      >
        Submit
      </Button>
    </UploadGalleryStyles.Container>
  );
};

export default UploadGallery;
