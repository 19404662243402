import emailjs from 'emailjs-com';
import { RequestServicePayload, SendApplicationFormPayload } from './types';

const serviceId = process.env.REACT_APP_APPLICATION_FORM_ID || '';
const userId = process.env.REACT_APP_EMAIL_USER_ID;

const sendApplicationForm = (payload: SendApplicationFormPayload) => {
  return new Promise((resolve, reject) => {
    const templateID = process.env.REACT_APP_APPLICATION_FORM_TEMPLATE_ID || '';
    emailjs.send(serviceId, templateID, payload, userId).then(
      () => {
        resolve(true);
      },
      (err) => reject(err)
    );
  });
};

const requestService = (payload: RequestServicePayload) => {
  return new Promise((resolve, reject) => {
    const templateID = process.env.REACT_APP_REQUEST_SERVICE_TEMPLATE_ID || '';
    emailjs.send(serviceId, templateID, payload, userId).then(
      () => {
        resolve(true);
      },
      (err) => reject(err)
    );
  });
};

const EmailServices = {
  sendApplicationForm,
  requestService,
};

export default EmailServices;
