import { useContext, useEffect } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import { useParams } from 'react-router-dom';

import LoaderBox from 'app/components/ui/LoaderBox';
import Text from 'app/components/ui/Text';
import ClientNewsContext from 'app/context/ClientNews/ClientNewsContext';
import Timeline from './components/TimeLine';
import { ReactComponent as GoBackIcon } from 'app/assets/icons/left-arrow.svg';
import { MainNewsStyles } from './styles';

const ClientNewsContent = () => {
  const contextValeus = useContext(ClientNewsContext);
  const params = useParams<{ id: string }>();
  const correspondingNews = contextValeus.data?.[params.id];

  useEffect(() => {
    contextValeus.fetchSingleNews({ id: params.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  if (
    contextValeus.status === 'fetching' &&
    !Object.keys(correspondingNews || {}).length
  )
    return <LoaderBox />;

  return (
    <MainNewsStyles.Container className="pad">
      <MainNewsStyles.News>
        <MainNewsStyles.GoBack to="/news">
          <GoBackIcon />
          <Text color="blue" size={30} weight={700}>
            News
          </Text>
        </MainNewsStyles.GoBack>
        <MainNewsStyles.EachNews>
          <Text as="h1" size={30}>
            {correspondingNews?.title}
          </Text>
          <Timeline date={correspondingNews?.updated_at || 0} />
          <img
            src={correspondingNews?.previewImage}
            alt={correspondingNews?.title}
            title={correspondingNews?.title}
          />
          <ReactMarkdown
            children={correspondingNews?.content || ''}
            allowDangerousHtml
          />
        </MainNewsStyles.EachNews>
      </MainNewsStyles.News>
    </MainNewsStyles.Container>
  );
};

export default ClientNewsContent;
