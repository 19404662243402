import { RouteProps } from 'react-router-dom';

import Home from 'app/pages/Home';
import About from 'app/pages/About';
import Gallery from 'app/pages/Gallery';
import Services from 'app/pages/Services';
import Academy from 'app/pages/Academy';
import Admin from 'app/pages/Admin';
import ClientNewsRoute from 'app/pages/ClientNews';
import RequestService from 'app/pages/RequestService';

export type RouteURLs =
  | '/home'
  | '/about'
  | '/academy'
  | '/gallery'
  | '/news'
  | '/service'
  | '/request-a-service'
  | '/request'
  | '/admin/login'
  | '/admin/news'
  | '/admin/gallery';

interface Routes {
  [key: string]: Array<
    RouteProps & { path: RouteURLs; id: string; title: string }
  >;
}

export const routes: Routes = {
  guestRoutes: [
    {
      id: 'home-nav',
      path: '/home',
      title: 'Home',
      exact: true,
      component: Home,
    },
    {
      id: 'about-nav',
      path: '/about',
      title: 'About',
      exact: true,
      component: About,
    },
    {
      id: 'service-nav',
      path: '/service',
      title: 'Services',
      exact: true,
      component: Services,
    },
    {
      id: 'academy-nav',
      path: '/academy',
      title: 'Academy',
      exact: true,
      component: Academy,
    },
    {
      id: 'gallery-nav',
      path: '/gallery',
      title: 'Gallery',
      exact: true,
      component: Gallery,
    },
    {
      id: 'news-nav',
      path: '/news',
      title: 'News',
      exact: false,
      component: ClientNewsRoute,
    },
  ],
  guestRoutesNotRenderedOnNavBar: [
    {
      id: 'request-a-service',
      path: '/request-a-service',
      title: 'Request A Service',
      exact: true,
      component: RequestService,
    },
  ],
  adminRoutes: [
    {
      id: 'admin-news',
      path: '/admin/news',
      title: 'Admin News',
      exact: false,
      component: Admin.News,
    },
    {
      id: 'admin-gallery',
      path: '/admin/gallery',
      title: 'Admin Gallery',
      exact: false,
      component: Admin.Gallery,
    },
  ],
  adminAuthRoutes: [
    {
      id: 'admin-login',
      path: '/admin/login',
      title: 'Admin Login',
      exact: true,
      component: Admin.Login,
    },
  ],
};
