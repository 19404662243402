interface Parameters {
	url?: string;
	text?: string;
	title?: string;
	files?: Array<File>;
	onSuccess?: () => void;
	onFailure?: (_: any) => void;
}

export default function share(params: Parameters) {
	const navigator = window.navigator as any;

	const shareParams = () => {
		navigator
			.share(params)
			.then(() => {
				params.onSuccess?.();
			})
			.catch((err: any) => params.onFailure?.(err));
	};

	//@ts-ignore
	if (navigator && navigator.share) {
		//@ts-ignore
		if (params.files && navigator.canShare && navigator.canShare({ files: params.files })) {
			return shareParams();
		}

		return shareParams();
	}

	return params.onFailure?.('Unsupported feature');
}
