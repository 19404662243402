export const getFileSizeInKB = (size: number) => parseInt(String(size / 1024));

export const convertHMTLToString = (value: string, maxLength: number = 100) => {
  let strimmedDetails = value.replace(/(<([^>]+)>)/gi, '');
  if (strimmedDetails.length > maxLength)
    return `${strimmedDetails.substr(0, maxLength)} ...`;
  return strimmedDetails;
};

export const encryptString = (value: string) => {
  return btoa(value);
};

type ObjectParams = { [key: string]: any };

export function mapKeys(
  items: ObjectParams[],
  key: string | string[],
  exclude: string[]
) {
  return items.reduce((obj: ObjectParams, currentObj: ObjectParams) => {
    const newObject = { ...currentObj };
    for (let item of exclude) {
      delete newObject[item];
    }

    if (typeof key === 'string') {
      obj[currentObj[key]] = newObject;
    } else {
      let newKey = currentObj[key[0]];
      for (let index = 1; index < key.length; index++) {
        newKey = `${newKey}_${currentObj[key[index]]}`;
      }
      obj[newKey] = newObject;
    }
    return obj;
  }, {});
}

export const replaceWhitespaceWithDash = (text: string): string => {
  return text.trim().replaceAll(' ', '-');
};
