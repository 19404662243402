import styled from '@emotion/styled';

import Colors from 'app/constants/Colors';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

const Header = styled.header`
  height: ${convertPixelToRem(60)};
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  padding: 0 ${convertPixelToRem(20)};
  background-color: ${Colors.white};
  align-items: center;

  .button-container {
    display: grid;
    grid-auto-flow: column;
    column-gap: ${convertPixelToRem(10)};
  }
`;

const Body = styled.div``;

const GeneralAdminStyles = {
  Header,
  Body,
};

export default GeneralAdminStyles;
