import Input from 'app/components/ui/Input';
import Text from 'app/components/ui/Text';
import ClientNewsContext from 'app/context/ClientNews/ClientNewsContext';
import Timeline from './TimeLine';
import { ClientNewsProps } from 'app/context/ClientNews/types';
import { useContext, useEffect, useState } from 'react';
import { NewsAsideStyles } from '../styles';

const AsideContent = () => {
  const contextValues = useContext(ClientNewsContext);
  const [recentNews, setRecentNews] = useState<ClientNewsProps['data']>(null);

  useEffect(() => {
    const news = {} as any;
    const newsKey = Object.keys(contextValues.data || {});
    for (let index = 0; index < 4 && index < newsKey.length; index++) {
      news[newsKey[index]] = contextValues.data?.[newsKey[index]];
    }
    setRecentNews(news);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NewsAsideStyles.NewsAside>
      <Input type="search" className="search" placeholder="Search ..." />
      <div>
        <Text color="blue" size={25}>
          Recent News
        </Text>
        <NewsAsideStyles.NewsAsideRecentNewsContainer>
          {Object.values(recentNews || {}).map((news) => {
            return (
              <NewsAsideStyles.NewsAsideRecentNews
                to={`/news/${news.id}`}
                key={news.id}
              >
                <img src={news.previewImage} alt={news.title} />
                <Text className="title" color="black">
                  {news.title}
                </Text>
                <Timeline date={news.updated_at} />
              </NewsAsideStyles.NewsAsideRecentNews>
            );
          })}
        </NewsAsideStyles.NewsAsideRecentNewsContainer>
      </div>
    </NewsAsideStyles.NewsAside>
  );
};

export default AsideContent;
