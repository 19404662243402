import styled from '@emotion/styled';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';

import Colors from 'app/constants/Colors';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

const NewsGrid = styled.div`
  display: grid;
  padding: ${convertPixelToRem(30)} ${convertPixelToRem(20)};
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: ${convertPixelToRem(30)};
  row-gap: ${convertPixelToRem(30)};
`;

const NewsGridItem = styled(Link)`
  display: grid;
  row-gap: ${convertPixelToRem(15)};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

  * {
    color: ${Colors.black};
  }

  img {
    width: 100%;
    height: ${convertPixelToRem(200)};
    object-fit: cover;
  }

  section {
    padding: ${convertPixelToRem(10)};
    padding-top: 0;
    display: grid;
    row-gap: ${convertPixelToRem(5)};

    p {
      color: rgba(0, 0, 0, 0.8);
    }
  }
`;

const ProcessNewsContainer = styled.form`
  width: 70%;
  max-width: ${convertPixelToRem(600)};
  margin: auto;
  margin-top: ${convertPixelToRem(50)};
  margin-bottom: ${convertPixelToRem(100)};
  display: grid;
  row-gap: ${convertPixelToRem(20)};
  input,
  textarea {
    border-color: #ccc !important;
    border-width: ${convertPixelToRem(1)} !important;
  }

  button {
    margin: auto;
  }
`;

const ProcessNewsEditorContainer = styled.div`
  display: grid;
  row-gap: ${convertPixelToRem(10)};
`;

const ProcessNewsEditor = styled(ReactQuill)`
  height: max-content;
  .ql-container.ql-snow {
    min-height: ${convertPixelToRem(250)};

    .ql-editor {
      min-height: ${convertPixelToRem(250)};
    }
  }
`;

const ViewNewsContainer = styled.article`
  display: grid;
  row-gap: ${convertPixelToRem(20)};
  padding: ${convertPixelToRem(20)};
  max-width: ${convertPixelToRem(700)};
  width: 70%;
  margin: auto;
`;

const ViewNewsActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${convertPixelToRem(10)};

  svg {
    width: ${convertPixelToRem(20)};
    height: ${convertPixelToRem(20)};
  }

  .edit-button {
    height: ${convertPixelToRem(35)};
    width: max-content;
    min-width: unset;
    padding: ${convertPixelToRem(10)} ${convertPixelToRem(20)};
    min-height: unset;
  }
`;

const ViewNewsContent = styled(ReactMarkdownWithHtml)`
  img {
    width: 100%;
  }
`;

const AdminNewsStyles = {
  NewsGrid,
  NewsGridItem,
};

export const ProcessNewsStyles = {
  Container: ProcessNewsContainer,
  EditorContainer: ProcessNewsEditorContainer,
  Editor: ProcessNewsEditor,
};

export const ViewAdminNewsStyles = {
  Container: ViewNewsContainer,
  Content: ViewNewsContent,
  Actions: ViewNewsActions,
};

export default AdminNewsStyles;
