import styled from '@emotion/styled';
import Text from 'app/components/ui/Text';
import Colors from 'app/constants/Colors';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

const Container = styled.section`
  padding-top: ${convertPixelToRem(30)};
  padding-bottom: ${convertPixelToRem(40)};
  background-color: ${Colors.gray};
`;

const AboutTitle = styled(Text)`
  color: ${Colors.blue};
  font-size: ${convertPixelToRem(25)};
`;

const AboutGrid = styled.div`
  display: grid;
  padding: ${convertPixelToRem(30)} 0 ${convertPixelToRem(60)};
  grid-template-columns: repeat(
    auto-fit,
    minmax(${convertPixelToRem(250)}, 1fr)
  );
  column-gap: ${convertPixelToRem(50)};
  row-gap: ${convertPixelToRem(50)};

  .item {
    display: grid;
    row-gap: ${convertPixelToRem(20)};
    background-color: ${Colors.white};
    box-shadow: 0px 0px 34px #00000059;
    justify-content: center;
    padding: ${convertPixelToRem(15)} ${convertPixelToRem(20)};

    * {
      text-align: center;
    }

    .icon {
      width: ${convertPixelToRem(40)};
      height: ${convertPixelToRem(40)};
      justify-self: center;
    }

    p {
      margin-top: ${convertPixelToRem(30)};
      line-height: ${convertPixelToRem(28)};
      font-weight: 300;
      justify-self: flex-start;
    }
  }
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${convertPixelToRem(250)}, 1fr)
  );
  column-gap: ${convertPixelToRem(50)};
  padding-top: ${convertPixelToRem(20)};
  padding-bottom: ${convertPixelToRem(40)};
  row-gap: ${convertPixelToRem(50)};

  .item {
    display: grid;
    row-gap: ${convertPixelToRem(26)};
    justify-content: center;
    height: fit-content;

    img {
      border-radius: 100%;
      width: ${convertPixelToRem(150)};
      height: ${convertPixelToRem(150)};
      margin: auto;
    }

    .qualification {
    }

    * {
      text-align: center;
    }

    .description {
      color: #000000b0;
    }
  }
`;

export const AboutStyles = {
  Container,
  AboutGrid,
  TeamGrid,
  Utils: {
    AboutTitle,
  },
};
