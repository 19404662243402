import { FC, InputHTMLAttributes } from 'react';
import Text from '../Text';

import { Container, Tag } from './styles';

type SelectOptionProp = { value: any; label: string };
type SelectOptionsProps = Array<SelectOptionProp>;

interface Props extends InputHTMLAttributes<any> {
  options: SelectOptionsProps;
  label?: string;
}

const Select: FC<Props> = ({ options, label, ...props }) => {
  return (
    <Container>
      {label && <Text as="label"> {label} </Text>}
      <Tag {...props}>
        {options?.map(({ label, value }) => {
          return (
            <option value={value} key={`${label}-${value}`}>
              {label}
            </option>
          );
        })}
      </Tag>
    </Container>
  );
};

export type { SelectOptionsProps, SelectOptionProp };
export default Select;
