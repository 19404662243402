import { FirebaseServices } from 'app/services';
import {
  FetchNewsPayload,
  FetchSingleNewsPayload,
} from 'app/services/firebase/types';
import { createContext, useState } from 'react';
import { ClientNewsProps } from './types';

const ClientNewsContext = createContext<ClientNewsProps>({
  data: null,
  status: 'idle',
  fetchNews: () => {},
  fetchSingleNews: () => {},
});

export const useClientNewsContext = (): ClientNewsProps => {
  const [status, setStatus] = useState<ClientNewsProps['status']>('idle');
  const [data, setData] = useState<ClientNewsProps['data']>(null);

  return {
    status,
    data,
    fetchNews: async (payload: FetchNewsPayload) => {
      try {
        setStatus('fetching');
        const res = await FirebaseServices.fetchNews(payload);
        const stateData: { [key: string]: any } = {};
        res.forEach((doc) => {
          stateData[doc.id] = doc.data();
        });
        setData(stateData);
        setStatus('fetched');
      } catch (error) {
        console.error({ error });
        setStatus('error');
      }
    },
    fetchSingleNews: async (payload: FetchSingleNewsPayload) => {
      try {
        setStatus('fetching');
        const res = await FirebaseServices.fetchSingleNews(payload);

        if (res) {
          setData((prevData) => ({
            ...prevData,

            [res.id]: res,
          }));
        }

        setStatus('fetched');
      } catch (error) {
        console.error({ error });
        setStatus('error');
      }
    },
  };
};

export default ClientNewsContext;
