import Text from 'app/components/ui/Text';
import { whatWeDoGrid } from 'app/data/whatWeDoGrid';
import { Helmet } from 'react-helmet';
import ServicesStyles from './styles';

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Services | Sinnot Technologies</title>
      </Helmet>
      <ServicesStyles.Container className="pad">
        {whatWeDoGrid.map((item) => {
          return (
            <ServicesStyles.GridItem key={item.image}>
              <img src={item.image} alt={item.image} />
              {item.title}
              <Text>{item.description}</Text>
            </ServicesStyles.GridItem>
          );
        })}
      </ServicesStyles.Container>
    </>
  );
};

export default Services;
