import styled from '@emotion/styled';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

const GalleryGrid = styled.div`
  display: grid;
  padding: ${convertPixelToRem(30)} ${convertPixelToRem(20)};
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: ${convertPixelToRem(30)};
  row-gap: ${convertPixelToRem(30)};
`;

const GalleryGridItem = styled.section`
  display: grid;
  row-gap: ${convertPixelToRem(15)};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

  img {
    width: 100%;
    height: ${convertPixelToRem(200)};
    object-fit: cover;
  }

  .caption-and-delete {
    display: flex;
    justify-content: space-between;
    padding: ${convertPixelToRem(10)};
    align-items: center;
    p {
      color: rgba(0, 0, 0, 0.8);
    }

    button {
      height: ${convertPixelToRem(30)};
      width: ${convertPixelToRem(60)};
      min-width: unset;
      min-height: unset;
    }
  }
`;

const UploadGalleryContainer = styled.form`
  width: 70%;
  max-width: ${convertPixelToRem(600)};
  margin: auto;
  margin-top: ${convertPixelToRem(50)};
  margin-bottom: ${convertPixelToRem(100)};
  display: grid;
  row-gap: ${convertPixelToRem(20)};
  input,
  textarea {
    border-color: #ccc !important;
    border-width: ${convertPixelToRem(1)} !important;
  }

  button {
    margin: auto;
  }
`;

export const AdminGalleryStyles = {
  Grid: GalleryGrid,
  GridItem: GalleryGridItem,
};

export const UploadGalleryStyles = {
  Container: UploadGalleryContainer,
};
