import { Helmet } from 'react-helmet';

import Text from 'app/components/ui/Text';
import CompanyImage from 'app/assets/images/company.png';
import VisionImage from 'app/assets/images/vision.png';
import MissionImage from 'app/assets/images/mission.png';
import CEOImage from 'app/assets/images/ceo.png';
import TrainingHeadImage from 'app/assets/images/training-head.png';
import { AboutStyles } from './styles';

const aboutGirdList: Array<{
  image: string;
  title: string;
  description: string;
}> = [
  {
    image: CompanyImage,
    title: 'Company Philosophy',
    description: `We view the satisfaction of our customers as the fundamental 
        drive of our clean energy solution services so 
        that we make every effort to provide high quality, 
        competitive and innovative solutions to meet and exceed their 
        expectation for quality products and services.`,
  },
  {
    image: VisionImage,
    title: 'Vision Statement',
    description: `To be the leading African technical service 
        provider that can be trusted by every household by delivering 
        value that are affordable and reliable without compromising 
        quality using the most effective tool based on a thorough 
        understanding of our customer needs and requirements.`,
  },
  {
    image: MissionImage,
    title: 'Mission Statment',
    description: `Rendering unbeatable solar solution 
        services, producing affordable quality local inverters, 
        advancing on innovation, creativity, researches and training 
        on clean energy technologies that provide solutions to energy 
        challenges and climate change in Africa.`,
  },
];

const teamList: Array<{
  image: string;
  name: string;
  position: string;
  description: string;
}> = [
  {
    image: CEOImage,
    name: 'Engr. Ayobami Adedokun (MNSE)',
    position: 'Founder',
    description: `COREN Registered Engineer and Member of Nigerian Society 
      of Engineers. 
      First and Second degree in Electrical Electronics Engineering at FUT, Akure. 
      Expert in Electronics, Automation and Solar Inverters design.`,
  },
  {
    image: TrainingHeadImage,
    name: 'Omotayo Adedokun',
    position: 'CEO',
    description: `
    Bachelor of Education in Educational Management at Ekiti state University. Excellent in administration and training management
    `,
  },
];

const About = () => {
  return (
    <>
      <Helmet>
        <title>About | Sinnot Technologies</title>
      </Helmet>
      <AboutStyles.Container className="pad">
        <Text lineHeight={28}>
          Sinnot Technologies Limited is a clean energy technology company
          dedicated to solving energy poverty in Africa through rendering
          unbeatable solar solution services (Micro or Mini grid), producing
          affordable quality local inverters, advancing on innovation,
          creativity, researches and training on various clean energy technology
          that provide solutions to energy challenges and climate change in
          Africa. Our target customers are Governments at all level for their
          various constituency projects such as solar installation for Basic
          Medical Centers, Streets lights etc. and also Students for their final
          electronics research projects, Banks for their remote area ATMs,
          Telecommunications Companies and Interested Individuals. We have a
          well-equipped electronic research laboratory and equipped training
          facilities to encourage our students and motivate our research focus
          in technological innovations.
        </Text>
        <AboutStyles.AboutGrid>
          {aboutGirdList.map(({ image, title, description }) => {
            return (
              <div className="item" key={`${title}-${description}`}>
                <img className="icon" src={image} alt={title} />
                <AboutStyles.Utils.AboutTitle>
                  {title}
                </AboutStyles.Utils.AboutTitle>
                <Text as="p">{description}</Text>
              </div>
            );
          })}
        </AboutStyles.AboutGrid>
        {/* @ts-ignore */}
        <AboutStyles.Utils.AboutTitle as="h3" align="center">
          Meet Our Team
        </AboutStyles.Utils.AboutTitle>
        <AboutStyles.TeamGrid>
          {teamList.map(({ description, image, name, position }) => {
            return (
              <div className="item" key={`${name}-${position}-${description}`}>
                <img
                  src={image}
                  className="icon"
                  alt={`${name}. ${position} at Sinnot`}
                  title={`${name}. ${position} at Sinnot`}
                />
                <div>
                  <AboutStyles.Utils.AboutTitle>
                    {name}
                    <br />
                    {position}
                  </AboutStyles.Utils.AboutTitle>
                </div>
                <Text className="description">{description}</Text>
              </div>
            );
          })}
        </AboutStyles.TeamGrid>
      </AboutStyles.Container>
    </>
  );
};

export default About;
