import { ReactNode } from 'react';
import Text from 'app/components/ui/Text';
import SolarPanelImage from 'app/assets/images/solar-panel.png';
import EnergyImage from 'app/assets/images/energy.png';
import CCTVImage from 'app/assets/images/CCTV.png';
import SalesImage from 'app/assets/images/sales.png';
import TrainingImage from 'app/assets/images/training.png';
import ConsultancyImage from 'app/assets/images/consultancy.png';

export const whatWeDoGrid: Array<{
  image: string;
  title: ReactNode;
  description: string;
}> = [
  {
    image: SolarPanelImage,
    title: (
      <div className="title">
        <Text>Solar Installation</Text> <br /> <Text>and Maintainance</Text>
      </div>
    ),
    description:
      'We offer Solar Inverters, Solar Boreholes, Solar Streetlights, etc.',
  },
  {
    image: EnergyImage,
    title: (
      <div className="title">
        <Text>Energy Audit and </Text>
        <Text> Management </Text>
      </div>
    ),
    description:
      'We offer services to track, analyse and optimize your energy usage',
  },
  {
    image: CCTVImage,
    title: <Text className="title"> CCTV </Text>,
    description: `We've got your security covered with sophisticated cameras and expert installation.`,
  },
  {
    image: SalesImage,
    title: <Text className="title">Sales</Text>,
    description: `Sales of solar components: Batteries, solar panels, bulbs, street
    lights, charge controllers, etc.`,
  },
  {
    image: TrainingImage,
    title: <Text className="title"> Training </Text>,
    description: `We have highly qualified and experienced staff to thoroughly educate
    trainees in all aspects of their chosen courses.`,
  },
  {
    image: ConsultancyImage,
    title: (
      <div className="title">
        <Text> Renewable Energy</Text> <br /> Consultancy
      </div>
    ),
    description: `We have highly qualified and experienced personnel to offer consultation
    on renewable energy.`,
  },
];
