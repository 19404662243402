import styled from '@emotion/styled';
import Colors from 'app/constants/Colors';
import media from 'app/utils/media';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: ${convertPixelToRem(20)};
  padding-bottom: ${convertPixelToRem(20)};
`;

const GridItem = styled.section`
  padding: ${convertPixelToRem(20)} 0;
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-areas: 'image title' 'image description';
  column-gap: ${convertPixelToRem(30)};
  row-gap: ${convertPixelToRem(20)};
  align-items: center;
  flex: 40%;
  width: max-content;

  margin: 0 ${convertPixelToRem(30)};

  ${media.bigTablet`
    flex : 60%;
  `}

  ${media.phone`
    grid-auto-flow : row;
    grid-template-areas : unset;
    grid-template-columns : unset;
  `}

  img {
    width: ${convertPixelToRem(150)};
    height: ${convertPixelToRem(150)};
    object-fit: cover;
    grid-area: image;
    ${media.phone`
      grid-area : unset
    `}
  }

  .title {
    grid-area: title;
    align-self: flex-end;
    color: ${Colors.blue};
    font-weight: 700;
    font-size: ${convertPixelToRem(25)};
    letter-spacing: ${convertPixelToRem(0.5)};
    ${media.phone`
      grid-area : unset
    `}
  }

  .description {
    grid-area: description;
    align-self: flex-start;
    ${media.phone`
      grid-area : unset
    `}
  }
`;
const ServicesStyles = {
  Container,
  GridItem,
};

export default ServicesStyles;
