import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Colors from 'app/constants/Colors';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

const InputContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  height: max-content;
  row-gap: ${convertPixelToRem(10)};
`;

export interface InputElementProps {
  error?: boolean;
}

const InputElement = styled.input<InputElementProps>`
  border: solid 2px ${Colors.blue};
  padding: 0 ${convertPixelToRem(10)} 0 ${convertPixelToRem(25)};

  height: ${convertPixelToRem(38)};
  ${(props) =>
    props.error &&
    css`
      border-color: ${Colors.red100};
      outline-color: ${Colors.red100};
    `}
  &[type = 'file'] {
    padding-top: ${convertPixelToRem(7)};
  }
`;

const InputStyles = {
  InputContainer,
  InputElement,
};

export default InputStyles;
