import { FirebaseServices } from 'app/services';
import {
  DeleteGalleryPayload,
  UploadGalleryPayload,
} from 'app/services/firebase/types';
import { createContext, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { AdminGalleryContextProps } from './types';

const AdminGalleryContext = createContext<AdminGalleryContextProps>({
  status: 'idle',
  data: null,
  fetchGallery: () => {},
  uploadGallery: (_: UploadGalleryPayload) => {},
  deleteGallery: async (_: DeleteGalleryPayload) => {},
});

export const useAdminGalleryContext = (): AdminGalleryContextProps => {
  const [status, setStatus] = useState<AdminGalleryContextProps['status']>(
    'idle'
  );
  const [data, setData] = useState<AdminGalleryContextProps['data']>(null);

  const fetchGallery = useCallback(async () => {
    try {
      setStatus('fetching');
      const res = await FirebaseServices.fetchGallery();
      const stateData: { [key: string]: any } = {};
      res.forEach((doc) => {
        stateData[doc.id] = doc.data();
      });
      setData(stateData);
      setStatus('fetched');
    } catch (error) {
      toast.error(error.message);
      setStatus('error');
    }
  }, []);

  const uploadGallery = useCallback(async (payload: UploadGalleryPayload) => {
    try {
      setStatus('submitting');
      await FirebaseServices.uploadGallery(payload);
      toast.success('Gallery uploaded successfully');
      setStatus('submitted');
    } catch (error) {
      toast.error(error.message);
      setStatus('error');
    }
  }, []);

  const deleteGallery = useCallback(
    async (payload: DeleteGalleryPayload) => {
      try {
        setStatus('deleting');
        await FirebaseServices.deleteGallery(payload);
        toast.success('Gallery deleted successfully');
        fetchGallery();
        setStatus('deleted');
      } catch (error) {
        toast.error(error.message);
        setStatus('error');
      }
    },
    [fetchGallery]
  );

  return {
    status,
    data,
    fetchGallery,
    uploadGallery,
    deleteGallery,
  };
};

export default AdminGalleryContext;
