import styled from '@emotion/styled';
import media from 'app/utils/media';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

const Container = styled.section`
  width: 60%;
  margin: auto;
  display: grid;
  row-gap: ${convertPixelToRem(30)};
  padding-top: ${convertPixelToRem(50)};
  padding-bottom: ${convertPixelToRem(50)};

  ${media.tablet`
    width : 90%;
  `}
`;

const Description = styled.header`
  display: grid;
  row-gap: ${convertPixelToRem(15)};
  * {
    text-align: center;
  }
`;

const Form = styled.form`
  display: grid;
  row-gap: ${convertPixelToRem(20)};

  button {
    margin-top: ${convertPixelToRem(40)} !important;
    margin: auto;
  }
`;

const RequestServiceStyles = {
  Container,
  Description,
  Form,
};

export default RequestServiceStyles;
