import {
  FC,
  FunctionComponent,
  SVGProps,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'app/components/ui/Button/Button';
import NavBar from './NavBar';
import Text from 'app/components/ui/Text';
import Footer from './Footer';
import { ReactComponent as FacebookIcon } from 'app/assets/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from 'app/assets/icons/twitter.svg';
import { ReactComponent as InstagramIcon } from 'app/assets/icons/instagram.svg';
import { ReactComponent as LinkedInIcon } from 'app/assets/icons/linkedin.svg';
import { ReactComponent as WhatsappIcon } from 'app/assets/icons/whatsapp.svg';
import { RouteURLs } from 'app/routes/Routes';
import { HeaderStyles, BodyStyles } from './styles';

const Overall: FC<{}> = ({ children }) => {
  return (
    <>
      <Header>
        <NavBar />
      </Header>
      <BodyStyles.Container>{children}</BodyStyles.Container>
      <Footer />
    </>
  );
};

const HomeHeaderFooterLink: Array<{
  id: string;
  icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title: string;
  url: string;
}> = [
  {
    id: 'facebook-link',
    title: 'Facebook',
    icon: FacebookIcon,
    url: 'https://www.facebook.com/Sinnotng',
  },
  {
    id: 'twitter-link',
    title: 'Twitter',
    icon: TwitterIcon,
    url: 'https://twitter.com/sinnotng',
  },
  {
    id: 'instagram-link',
    title: 'Instagram',
    icon: InstagramIcon,
    url: 'https://www.instagram.com/sinnotng/',
  },
  {
    id: 'linkedin-link',
    title: 'Linkedin',
    icon: LinkedInIcon,
    url: 'https://www.linkedin.com/company/sinnotng',
  },
  {
    id: 'whatsapp-link',
    title: 'Whatsapp',
    icon: WhatsappIcon,
    url: 'https://wa.me/+2348068118888',
  },
];

const Header: FC<{}> = ({ children }) => {
  const location = useLocation();
  const pathname = location.pathname as RouteURLs;
  const history = useHistory();
  const [isNavBarColorVisible, setIsNavBarColorVisible] =
    useState<boolean>(false);

  const headerRef = useRef<HTMLElement>(null);

  const handleScroll = () => {
    const headerRefPosition = headerRef.current?.offsetHeight;

    const windowPageOffset = window.pageYOffset;

    if (headerRefPosition && windowPageOffset >= headerRefPosition) {
      return setIsNavBarColorVisible(true);
    }
    return setIsNavBarColorVisible(false);
  };

  const navigateToServicePage = () => {
    history.push('/service');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HeaderStyles.Container
      url={pathname}
      isNavBarColorVisible={isNavBarColorVisible}
    >
      {children}
      {pathname === '/home' && (
        <>
          <section className="content" ref={headerRef}>
            <header>
              <Text as="p"> Solving Energy Poverty In</Text>
              <Text as="h1"> Africa</Text>
            </header>
            <Button onClick={navigateToServicePage}> Our Services </Button>
          </section>
          <div className="footer">
            {HomeHeaderFooterLink.map(({ icon, id, title, url }) => {
              const Icon = icon;
              return (
                <Button
                  variant="text"
                  key={id}
                  title={title}
                  as="a"
                  //@ts-ignore
                  href={url}
                  target="_blank"
                  rel="noopener"
                >
                  <Icon className="icon" />
                </Button>
              );
            })}
          </div>
        </>
      )}
      {pathname === '/about' && (
        <section className="content" ref={headerRef}>
          <p>About</p>
          <h1>US</h1>
        </section>
      )}

      {pathname === '/service' && (
        <section className="content" ref={headerRef}>
          <p> Our </p> <h1>Services</h1>
        </section>
      )}
      {pathname === '/gallery' && (
        <section className="content" ref={headerRef}>
          <p> Our </p> <h1>Gallery</h1>
        </section>
      )}
      {pathname.match('/news') && (
        <section className="content" ref={headerRef}>
          <p> Sinnot </p> <h1>NEWS</h1>
        </section>
      )}
      {pathname === '/request' && (
        <section className="content" ref={headerRef}>
          <p> Request a </p> <h1>Service</h1>
        </section>
      )}
      {pathname === '/academy' && (
        <section className="content" ref={headerRef}>
          <p> Solar-Inverter </p> <h1>Academy</h1>
        </section>
      )}
      {pathname === '/request-a-service' && (
        <section className="content" ref={headerRef}>
          <p> Request a </p> <h1>Service</h1>
        </section>
      )}
    </HeaderStyles.Container>
  );
};

export default Overall;
