import styled from '@emotion/styled';
import Colors from 'app/constants/Colors';
import dropdownIcon from 'app/assets/icons/dropdown.svg';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

const SelectContainer = styled.div`
  display: grid;
  row-gap: ${convertPixelToRem(5)};
`;

const SelectTag = styled.select`
  border: solid 2px ${Colors.blue};
  padding: 0 ${convertPixelToRem(10)} 0 ${convertPixelToRem(25)};
  background: transparent;
  height: ${convertPixelToRem(38)};
  background: url(${dropdownIcon}) 96% / 2% no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
`;

export { SelectContainer as Container, SelectTag as Tag };
