import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Button from 'app/components/ui/Button';
import Input from 'app/components/ui/Input';
import Select from 'app/components/ui/Select';
import Text from 'app/components/ui/Text';
import TextArea from 'app/components/ui/TextArea';
import RequestServiceStyles from './styles';
import { RequestServicePayload } from 'app/services/email/types';
import RequestServiceContext, {
  ProductType,
  useRequestServiceContext,
} from 'app/context/RequestService/RequestServiceContext';
import { useEffect } from 'react';
import useQueryURL from 'app/hooks/useQueryURL';
import { Helmet } from 'react-helmet';

type FormData = RequestServicePayload;

const Schema = yup.object().shape({
  email: yup
    .string()
    .email('Kindly input a valid email')
    .required('Kindly input your email address'),
  fullName: yup.string().required('Kindly input your fullname'),
  message: yup
    .string()
    .required('Kindly input your message')
    .min(10, 'Message cannot be less than 10 characters'),
  phone: yup.string().required('Kindly input your phone number'),
});

const productSelectOptions: Array<{
  label: ProductType | string;
  value: ProductType | string;
}> = [
  {
    label: 'SHM',
    value: 'SHM',
  },
  {
    label: 'SHB',
    value: 'SHB',
  },
  {
    label: 'SHP',
    value: 'SHP',
  },
  {
    label: 'SHU',
    value: 'SHU',
  },
  {
    label: 'Solar Installation and Maintainance',
    value: 'Solar Installation and Maintainance',
  },
  {
    label: 'Energy Audit and Management',
    value: 'Energy Audit and Management',
  },
  {
    label: 'CCTV',
    value: 'CCTV',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Training',
    value: 'Training',
  },
  {
    label: 'Renewable Energy and Consultancy',
    value: 'Renewable Energy and Consultancy',
  },
];

const packageOptionsSelectOptions: Array<{ label: string; value: string }> = [
  {
    label: 'Lite',
    value: 'Lite',
  },
  {
    label: 'Pro',
    value: 'Pro',
  },
  {
    label: 'Max',
    value: 'Max',
  },
];

const RequestService = () => {
  const query = useQueryURL();
  const productOptionSelectedFromQuery = query.get('product');
  const { control, handleSubmit, errors, reset } = useForm<FormData>({
    defaultValues: {
      fullName: '',
      email: '',
      phone: '',
      product: productOptionSelectedFromQuery || 'SHM',
      message: '',
      company: '',
      packageOption: 'Lite',
    },
    resolver: yupResolver(Schema),
  });

  const contextValues = useRequestServiceContext();

  const submit = (data: FormData) => {
    contextValues.submit?.(data);
  };

  useEffect(() => {
    if (contextValues.status === 'submitted') {
      reset();
    }
  }, [contextValues.status, reset]);

  return (
    <>
      <Helmet>
        <title>Request a Service | Sinnot Technologies </title>
      </Helmet>
      <RequestServiceContext.Provider value={contextValues}>
        <RequestServiceStyles.Container>
          <RequestServiceStyles.Description>
            <Text as="h3" color="blue">
              Welcome to SINNOT Service Request Page
            </Text>
            <Text>
              Kindly fill the form below and we'll contact you immediately.
            </Text>
          </RequestServiceStyles.Description>
          <RequestServiceStyles.Form onSubmit={handleSubmit(submit)}>
            <Controller
              as={
                <Input
                  placeholder="Full Name"
                  required
                  label="Full Name"
                  errorMessage={errors.fullName?.message}
                />
              }
              control={control}
              name="fullName"
            />
            <Controller
              as={
                <Input
                  type="email"
                  placeholder="Email "
                  label="Email"
                  required
                  errorMessage={errors.email?.message}
                />
              }
              control={control}
              name="email"
            />
            <Controller
              as={
                <Input
                  type="tel"
                  placeholder="Phone "
                  label="Phone"
                  required
                  errorMessage={errors.phone?.message}
                />
              }
              control={control}
              name="phone"
            />
            <Controller
              as={<Input placeholder="Company" label="Company" />}
              control={control}
              name="company"
            />
            <Controller
              render={({ name, onChange, value }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  name={name}
                  options={productSelectOptions}
                  label="Product"
                />
              )}
              control={control}
              name="product"
            />

            <Controller
              render={({ name, onChange, value }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  name={name}
                  options={packageOptionsSelectOptions}
                  label="Package Option"
                />
              )}
              control={control}
              name="packageOption"
            />
            <Controller
              as={
                <TextArea
                  placeholder="Message"
                  required
                  label="Message"
                  errorMessage={errors.message?.message}
                />
              }
              control={control}
              name="message"
            />
            <Button
              variant="secondary"
              isLoading={contextValues.status === 'submitting'}
              type="submit"
            >
              Place Request
            </Button>
          </RequestServiceStyles.Form>
        </RequestServiceStyles.Container>
      </RequestServiceContext.Provider>
    </>
  );
};

export default RequestService;
