import { css } from '@emotion/react';

const mediaQuery = (query: any) => (rules: any) => {
  return css`
    @media (${query}) {
      ${rules}
    }
  `;
};

const media = {
  laptop: mediaQuery(`max-width : 1400px`),
  bigTablet: mediaQuery(`max-width:1150px`),
  tablet: mediaQuery(`max-width:700px`),
  phone: mediaQuery(`max-width:500px`),
  smallPhone: mediaQuery(`max-width:480px`),
};

export default media;
