const Colors: { [key: string]: string } = {
  yellow: '#FFF700',
  yellow100: '#FFFC9F',
  white: '#FFFFFF',
  black: '#000000',
  blue: '#3A76D9',
  blue100: 'rgba(0, 67, 180, 0.57)',
  blue200: 'rgba(0, 67, 180, 0.2)',
  gray: '#F3F3F3',
  gray100: '#637381',
  red: '#FF0000',
  red100: 'rgba(255, 0, 0, 0.8)',
  red200: 'rgb(255, 0, 0.9)',
  orange: '#FFA500',
  darkyellow: '#FFD700',
};

export default Colors;
