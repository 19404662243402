import { FC } from 'react';
import ReactPagination, { ReactPaginateProps } from 'react-paginate';
import PaginationStyles from './styles';

interface PaginationProps extends ReactPaginateProps {
  className?: string;
}

const Pagination: FC<PaginationProps> = ({ className, ...props }) => {
  return (
    <PaginationStyles.Container className={className}>
      <ReactPagination
        {...props}
        pageClassName="page"
        activeClassName="active"
        previousClassName="nav-button nav-button--previous"
        nextClassName="nav-button nav-button--next"
        containerClassName="container"
      />
    </PaginationStyles.Container>
  );
};

export default Pagination;
