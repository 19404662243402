import styled from '@emotion/styled';
import Colors from 'app/constants/Colors';
import media from 'app/utils/media';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

const GalleryContainer = styled.section`
  display: grid;
  row-gap: ${convertPixelToRem(35)};
  padding-top: ${convertPixelToRem(35)};
  padding-bottom: ${convertPixelToRem(50)};

  * {
    color: ${Colors.blue};
  }

  .pagination {
    padding-top: ${convertPixelToRem(35)};
  }
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${convertPixelToRem(300)}, 1fr)
  );
  column-gap: ${convertPixelToRem(40)};
  row-gap: ${convertPixelToRem(30)};

  ${media.bigTablet`
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  );
  `}
`;

const GalleryGridItem = styled.section`
  display: grid;
  row-gap: ${convertPixelToRem(10)};

  img {
    width: 100%;
    object-fit: cover;
    height: ${convertPixelToRem(300)};
  }
`;

const GalleryStyles = {
  Container: GalleryContainer,
  Grid: GalleryGrid,
  GridItem: GalleryGridItem,
};

export default GalleryStyles;
