import { useCallback, useState } from 'react';
import { ReactPaginateProps } from 'react-paginate';
import useDeepCompareEffect from 'use-deep-compare-effect';
import useComponentDidUpdate from './useComponentDidUpdate';

function usePagination<T extends { [key: string]: any }>(
  propsData: T,
  limit: number = 20
) {
  const [currentDisplayedData, setcurrentDisplayedData] = useState<T | null>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);

  const getPropsKey = useCallback(() => {
    return Object.keys(propsData);
  }, [propsData]);

  const handlePageChange: ReactPaginateProps['onPageChange'] = ({
    selected: page,
  }) => {
    if (page >= 0 && page < getPropsKey().length) {
      setCurrentPage(page);
    }
  };

  useDeepCompareEffect(() => {
    if (Object.keys(propsData).length) {
      const initialDisplayedData: any = {};
      const propsKey = getPropsKey();
      for (let index = 0; index < limit && index < propsKey.length; index++) {
        initialDisplayedData[propsKey[index]] = propsData[propsKey[index]];
      }

      setcurrentDisplayedData(initialDisplayedData);
      setNumberOfPages(Math.ceil(propsKey.length / limit));
    } else {
      setcurrentDisplayedData(null);
      setNumberOfPages(1);
    }
  }, [limit, getPropsKey, propsData]);

  useComponentDidUpdate(() => {
    const startingIndex = currentPage
      ? Math.floor(limit / currentPage)
      : currentPage;
    const endingIndex = startingIndex + limit;

    const newDisplayedData: any = {};
    const propsKey = getPropsKey();

    for (
      let index = startingIndex;
      index < endingIndex && index < propsKey.length;
      index++
    ) {
      newDisplayedData[propsKey[index]] = propsData[propsKey[index]];
    }

    setcurrentDisplayedData(newDisplayedData);
  }, [currentPage]);

  return {
    numberOfPages,
    currentPage,
    currentDisplayedData,
    handlePageChange,
  };
}

export default usePagination;
