import { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';

import Logo from 'app/assets/images/logo.png';
import Button from 'app/components/ui/Button/Button';
import { ReactComponent as MenuIcon } from 'app/assets/icons/menu.svg';
import { NavBarStyles } from './styles';
import { routes } from 'app/routes/Routes';
import { useHistory } from 'react-router-dom';

const NavBar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const history = useHistory();

  const handleClickAway = () => {
    setIsSidebarOpen(false);
  };

  const handleBarClick = () => {
    setIsSidebarOpen(true);
  };

  const navigateToRequestAService = () => {
    history.push('/request-a-service');
  };

  return (
    <NavBarStyles.Container isSideBardOpen={isSidebarOpen}>
      <img src={Logo} className="logo" alt="Sinnot technology" />

      <ClickAwayListener
        as="button"
        onClick={handleBarClick}
        onClickAway={handleClickAway}
        className="bar"
      >
        <MenuIcon className="element" />
      </ClickAwayListener>
      <div className="links">
        {routes.guestRoutes.map(({ id, title, component: _, ...props }) => {
          return (
            <NavBarStyles.Link
              key={id}
              activeClassName="active-link"
              to={props.path}
              {...props}
            >
              {title}
            </NavBarStyles.Link>
          );
        })}
        <Button onClick={navigateToRequestAService}>Request a Quote</Button>
      </div>
    </NavBarStyles.Container>
  );
};

export default NavBar;
