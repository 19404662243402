import course1 from 'app/assets/images/course-1.png';
import course2 from 'app/assets/images/course-2.png';
import course3 from 'app/assets/images/course-3.png';
import course4 from 'app/assets/images/course-4.png';

export interface AcademyDataProps {
  title: string;
  image: string;
  description: string;
}

const academyData: Array<AcademyDataProps> = [
  {
    title: 'SOLAR INSTALLATION AND MAINTENANCE',
    description:
      'Fundamentals of solar systems, Equipment analysis, Maintenance strategy, etc.',
    image: course1,
  },
  {
    title: 'INVERTER DESIGN',
    description:
      'Fundamentals of solar Inverters, Design and Function, Operational Dynamics, etc.',
    image: course2,
  },
  {
    title: 'AUTOMATION AND EMBEDDED SYSTEMS',
    description:
      'Fundamentals of Automation and Embedded Systems, Operational Dynamics, etc.',
    image: course3,
  },
  {
    title: 'ENERGY AUDIT AND MANAGEMENT',
    description:
      'Learn the Principles of Safe Energy Conservation and Management; and much more...',
    image: course4,
  },
];

export default academyData;
