import { useCallback, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'app/components/ui/Button';
import LoaderBox from 'app/components/ui/LoaderBox';
import Text from 'app/components/ui/Text';
import AdminNewsContext from 'app/context/Admin/News/AdminNewsContext';
import { ReactComponent as GoBackIcon } from 'app/assets/icons/left-arrow.svg';
import { ViewAdminNewsStyles } from './styles';

const ViewAdminNews = () => {
  const { id } = useParams<{ id: string }>();
  const contextValues = useContext(AdminNewsContext);
  const history = useHistory();

  const correspondingNews = contextValues.data?.[id];

  const navigateToAdminHome = useCallback(() => {
    history.push('/admin/news');
  }, [history]);

  const navigateToEditPage = () => {
    history.push(`/admin/news/edit/${id}`);
  };

  const handleNewsDelete = () => {
    const dialogAnswer = window.confirm(
      'Are you sure you want to delete this news?'
    );
    if (dialogAnswer) {
      contextValues.deleteNews({ id });
    }
  };

  useEffect(() => {
    contextValues.fetchSingleNews({ id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contextValues.status === 'deleted') {
      navigateToAdminHome();
    }
  }, [contextValues.status, navigateToAdminHome]);

  if (contextValues.status === 'fetching' || !correspondingNews)
    return <LoaderBox />;

  return (
    <ViewAdminNewsStyles.Container>
      <ViewAdminNewsStyles.Actions>
        <Button variant="text" onClick={navigateToAdminHome}>
          <GoBackIcon />
        </Button>
        <ViewAdminNewsStyles.Actions>
          <Button
            variant="destructive"
            isLoading={contextValues.status === 'deleting'}
            className="edit-button"
            onClick={handleNewsDelete}
          >
            Delete
          </Button>
          <Button
            variant="secondary"
            className="edit-button"
            onClick={navigateToEditPage}
            disabled={contextValues.status === 'deleting'}
          >
            Edit
          </Button>
        </ViewAdminNewsStyles.Actions>
      </ViewAdminNewsStyles.Actions>
      <Text as="h3" size={26}>
        {correspondingNews.title}
      </Text>
      <ViewAdminNewsStyles.Content
        allowDangerousHtml
        children={correspondingNews.content}
      />
    </ViewAdminNewsStyles.Container>
  );
};

export default ViewAdminNews;
