import { FirebaseServices } from 'app/services';
import {
  AddNewsPayload,
  DeleteNewsPayload,
  EditNewsPayload,
  FetchNewsPayload,
  FetchSingleNewsPayload,
} from 'app/services/firebase/types';
import { createContext, useState } from 'react';
import { toast } from 'react-toastify';
import { AdminNewsContextProps } from './types';

const AdminNewsContext = createContext<AdminNewsContextProps>({
  data: null,
  status: 'idle',
  fetchNews: () => {},
  addNews: () => {},
  fetchSingleNews: () => {},
  editNews: () => {},
  deleteNews: () => {},
});

export const useAdminNewsContext = (): AdminNewsContextProps => {
  const [status, setStatus] = useState<AdminNewsContextProps['status']>('idle');
  const [data, setData] = useState<AdminNewsContextProps['data']>(null);

  return {
    status,
    data,
    fetchNews: async (payload: FetchNewsPayload) => {
      try {
        setStatus('fetching');
        const res = await FirebaseServices.fetchNews(payload);
        const stateData: { [key: string]: any } = {};
        res.forEach((doc) => {
          stateData[doc.id] = doc.data();
        });
        setData(stateData);
        setStatus('fetched');
      } catch (error) {
        toast.error(error.message);
        setStatus('error');
      }
    },
    addNews: async (payload: AddNewsPayload) => {
      try {
        setStatus('submitting');
        await FirebaseServices.addNews(payload);
        toast.success('News added successfully');
        setStatus('submitted');
      } catch (error) {
        toast.error(error.message);
        setStatus('error');
      }
    },
    editNews: async (payload: EditNewsPayload) => {
      try {
        setStatus('editing');
        await FirebaseServices.editNews(payload);
        toast.success('News edited successfully');
        setStatus('edited');
      } catch (error) {
        toast.error(error.message);
        setStatus('error');
      }
    },
    fetchSingleNews: async (payload: FetchSingleNewsPayload) => {
      try {
        setStatus('fetching');
        const res = await FirebaseServices.fetchSingleNews(payload);

        if (res) {
          setData((prevData) => ({
            ...prevData,

            [res.id]: res,
          }));
        }

        setStatus('fetched');
      } catch (error) {
        setStatus('error');
      }
    },
    deleteNews: async (payload: DeleteNewsPayload) => {
      try {
        setStatus('deleting');
        await FirebaseServices.deleteNews(payload);
        toast.success('News deleted successfully');
        setStatus('deleted');
      } catch (error) {
        toast.error(error.message);
        setStatus('error');
      }
    },
  };
};

export default AdminNewsContext;
