import createActionType from 'app/utils/createActionType';
import { LoginPayload } from 'app/services/firebase/types';
import { GenericAction, StatusType } from '../../types';

export interface AdminLoginContextProps {
  status: StatusType;
  token: null | string;
  submit: ((_: LoginPayload) => void) | null;
  logout: () => void;
}

export type AdminLoginContextPropsWithoutSubmit = Omit<
  AdminLoginContextProps,
  'submit' | 'logout'
>;

export type AdminContextAction = GenericAction;

export const updateAdminLoginStatus = createActionType('update_admin_status');
export const updateAdminLoginToken = createActionType('update_admin_token');
export const updateAdminLogoutStatus = createActionType(
  'update_admin_logout_status'
);
