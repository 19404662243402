import { css } from '@emotion/react';

const globalStyles = css`
  * {
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }
  p {
    font-weight: 300;
  }

  a {
    text-decoration: none;
  }

  button {
    &:hover {
      cursor: pointer;
    }
  }
`;

export default globalStyles;
