import styled from '@emotion/styled';

import Text from 'app/components/ui/Text';
import Colors from 'app/constants/Colors';
import SolarImage from 'app/assets/images/solar-bg.png';
import AcademyImage from 'app/assets/images/academy-bg.png';
import SHUBGImage from 'app/assets/images/shu-bg.png';
import { convertPixelToRem } from 'app/utils/stylesHelpers';
import media from 'app/utils/media';

const Title = styled(Text)`
  font-size: ${convertPixelToRem(30)};
  color: ${Colors.blue};
  font-weight: 700;
`;

const IntroductionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: ${convertPixelToRem(20)};
  align-items: center;
  padding-top: ${convertPixelToRem(50)};
  padding-bottom: ${convertPixelToRem(50)};
  ${media.tablet`
  flex-wrap: wrap;

	`}
`;

const IntroductionText = styled.section`
  display: grid;
  height: max-content;
  row-gap: ${convertPixelToRem(25)};

  h3 {
  }
  span {
    line-height: ${convertPixelToRem(30)};
  }
`;

const IntroductionImage = styled.img`
  max-width: 50%;
  height: ${convertPixelToRem(300)};
  object-fit: contain;
  align-self: flex-end;
  ${media.tablet`
		 width : 100%;
		 max-width : unset
		`}
`;

const WhatWeDoContainer = styled.section`
  display: grid;
  row-gap: ${convertPixelToRem(20)};
  justify-content: center;
  padding: ${convertPixelToRem(20)} 10% ${convertPixelToRem(30)};
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.92),
      rgba(255, 255, 255, 0.92)
    ),
    url(${SolarImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  * {
    text-align: center;
  }

  button {
    margin: auto;
  }

  .grid-container {
    display: flex;
    justify-content: space-between;
    row-gap: ${convertPixelToRem(15)};
    flex-wrap: wrap;

    .grid-item {
      padding: ${convertPixelToRem(10)} 0;
      flex: 30%;
      display: flex;
      flex-direction: column;
      row-gap: ${convertPixelToRem(20)};
      align-items: center;

      img {
        background-color: rgba(6, 221, 255, 0.29);
        width: ${convertPixelToRem(200)};
        height: ${convertPixelToRem(200)};
        border-radius: 100%;
      }

      * {
        color: ${Colors.blue};
        font-size: ${convertPixelToRem(18)};
        line-height: ${convertPixelToRem(28)};
      }
    }
  }
`;

const JustForYouOfferContainer = styled.section`
  padding: ${convertPixelToRem(30)} 0 ${convertPixelToRem(20)};

  .badge {
    background-color: ${Colors.blue};
    height: ${convertPixelToRem(50)};
    display: flex;
    align-items: center;
    width: 40%;
    padding-left: 13%;

    ${media.bigTablet`
			width : max-content;
			padding-right :20px;
		`}

    * {
      color: ${Colors.white};
      font-size: ${convertPixelToRem(25)};
    }

    .tag {
      color: ${Colors.yellow};
    }
  }

  .flex {
    display: flex;
    column-gap: ${convertPixelToRem(15)};
    flex-wrap: wrap;
  }
`;

const JustForYouImage = styled.img`
  object-fit: contain;
  width: 50%;
  height: ${convertPixelToRem(400)};
  ${media.tablet`
		 width : 100%;
		`}
`;

const JustForYouDescription = styled.section`
  display: grid;
  row-gap: ${convertPixelToRem(20)};
  height: max-content;
  .title {
    display: flex;

    * {
      color: ${Colors.blue};
    }

    h3 {
      font-size: ${convertPixelToRem(50)};
    }

    p {
      font-weight: bolder;
      align-self: flex-end;
      color: rgba(58, 118, 217, 0.5);
      letter-spacing: ${convertPixelToRem(2)};
      padding-bottom: ${convertPixelToRem(9)};
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: ${convertPixelToRem(20)};

    .grid-item {
      display: flex;
      column-gap: ${convertPixelToRem(5)};
      align-items: center;

      .grid-item-icon {
        fill: ${Colors.blue};
        width: ${convertPixelToRem(20)};
        height: ${convertPixelToRem(20)};
      }
    }
  }

  .price {
    font-size: ${convertPixelToRem(50)};
  }

  ${media.tablet`
  padding-left: 13%;

	`}
`;

const SHUContainer = styled.section`
  display: flex;
  column-gap: ${convertPixelToRem(15)};
  padding: ${convertPixelToRem(30)} 0 ${convertPixelToRem(20)} 13%;
  background-image: url(${SHUBGImage});
  align-items: center;
  flex-wrap: wrap;

  * {
    color: ${Colors.white};
  }

  button {
    color: ${Colors.black};
  }
`;

const SHUImage = styled.img`
  object-fit: contain;
  width: 50%;
  height: ${convertPixelToRem(600)};
  ${media.tablet`
		 width : 100%;
		`}
`;

const SHUDescription = styled.section`
  display: grid;
  row-gap: ${convertPixelToRem(20)};
  height: max-content;
  .title {
    display: flex;

    h3 {
      font-size: ${convertPixelToRem(50)};
      color: ${Colors.yellow};
    }

    p {
      font-weight: bolder;
      align-self: flex-end;
      color: rgba(58, 118, 217, 0.5);
      letter-spacing: ${convertPixelToRem(2)};
      color: rgba(255, 255, 255, 0.8);
      padding-bottom: ${convertPixelToRem(9)};
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: ${convertPixelToRem(20)};

    .grid-item {
      display: flex;
      column-gap: ${convertPixelToRem(5)};
      align-items: center;

      .grid-item-icon {
        fill: ${Colors.yellow};
        width: ${convertPixelToRem(20)};
        height: ${convertPixelToRem(20)};
      }
    }
  }

  .price {
    font-size: ${convertPixelToRem(50)};
  }
  ${media.tablet`
  padding-right: 20px;

	`}
`;

export const PackageTypeContainer = styled.div`
  display: flex;
  column-gap: ${convertPixelToRem(20)};
  align-items: center;
  ${media.tablet`
  flex-wrap: wrap;
	`}
`;

export const PackageTypeBadge = styled.span`
  border-radius: ${convertPixelToRem(24)};
  padding: ${convertPixelToRem(10)} ${convertPixelToRem(30)};
  background-color: ${(props) => Colors[props.color || 'yellow']};
  font-size: ${convertPixelToRem(14)};
  font-weight: 400;
  color: ${Colors.black};
  &:hover {
    opacity: 0.6;
  }
`;

const SHBContainer = styled(SHUContainer)``;

const SHBImage = styled(SHUImage)``;

const SHBDescription = styled(SHUDescription)``;

const SHPContainer = styled(JustForYouOfferContainer)``;

const SHPImage = styled(JustForYouImage)``;

const SHPDescription = styled(JustForYouDescription)``;

const AcademyContainer = styled.section`
  padding: ${convertPixelToRem(20)} 0 ${convertPixelToRem(40)};
  display: grid;
  row-gap: ${convertPixelToRem(20)};
  background-image: linear-gradient(${Colors.blue200}, ${Colors.blue200}),
    url(${AcademyImage});
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  min-height: ${convertPixelToRem(400)};

  ${media.tablet`
	   background-size : cover;
		 
	`}

  .badge {
    padding-left: 10%;
    max-width: 50%;
    border-top-right-radius: ${convertPixelToRem(50)};
    border-bottom-right-radius: ${convertPixelToRem(50)};
    background-color: ${Colors.yellow};
    min-height: ${convertPixelToRem(50)};
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 20px #000000b3;

    ${media.bigTablet`
		 width : max-content;
		 max-width : unset;
		 padding-right : 20px;
		`}

    * {
      text-transform: uppercase;
      font-weight: 600;
      font-size: ${convertPixelToRem(25)};
    }
  }

  > div {
    padding-left: 10%;
    max-width: 40%;
    line-height: ${convertPixelToRem(28)};

    ${media.bigTablet`
		 width : max-content;
		 max-width : 60%;
		`}

    p {
      font-weight: 500;
    }
  }
`;

export const Introduction = {
  Container: IntroductionContainer,
  Text: IntroductionText,
  Image: IntroductionImage,
};

export const WhatWeDo = {
  Container: WhatWeDoContainer,
};

export const JustForYou = {
  Container: JustForYouOfferContainer,
  Image: JustForYouImage,
  Description: JustForYouDescription,
};

export const SHU = {
  Container: SHUContainer,
  Image: SHUImage,
  Description: SHUDescription,
};

export const SHB = {
  Container: SHBContainer,
  Image: SHBImage,
  Description: SHBDescription,
};

export const SHP = {
  Container: SHPContainer,
  Image: SHPImage,
  Description: SHPDescription,
};

export const Academy = {
  Container: AcademyContainer,
};

export const HomeUtils = {
  Title: Title,
};
