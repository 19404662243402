import AdminGalleryContext from 'app/context/Admin/Gallery/AdminGalleryContext';
import usePagination from 'app/hooks/usePagination';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { AdminGalleryStyles } from './styles';
import { Gallery as GalleryItemProps } from 'app/services/firebase/types';
import LoaderBox from 'app/components/ui/LoaderBox';
import Pagination from 'app/components/ui/Pagination';
import Button from 'app/components/ui/Button';

const AdminGallery = () => {
  const { data, status, fetchGallery } = useContext(AdminGalleryContext);
  const {
    currentDisplayedData,
    numberOfPages,
    handlePageChange,
    currentPage,
  } = usePagination(data || {});

  const galleryData = Object.keys(currentDisplayedData || {});

  useEffect(() => {
    fetchGallery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((status === 'fetching' || status === 'idle') && !data)
    return <LoaderBox />;
  if (!galleryData.length) return <p>Empty Gallery </p>;

  return (
    <>
      <AdminGalleryStyles.Grid>
        {Object.values(currentDisplayedData || {}).map((props) => {
          return <GalleryItem {...props} key={props.id} />;
        })}
      </AdminGalleryStyles.Grid>
      <Pagination
        pageCount={numberOfPages}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        className="pagination"
        onPageChange={handlePageChange}
        forcePage={currentPage}
      />
    </>
  );
};

const GalleryItem: FC<GalleryItemProps> = (props) => {
  const { deleteGallery } = useContext(AdminGalleryContext);
  const [deleting, setDeleting] = useState(false);

  const handleDeleteGallery = useCallback(async () => {
    setDeleting(true);
    await deleteGallery({ id: props.id });
    setDeleting(false);
  }, [deleteGallery, props]);

  return (
    <AdminGalleryStyles.GridItem>
      <img src={props.imageURL} alt={props.caption} />
      <div className="caption-and-delete">
        <p>{props.caption}</p>
        <Button
          variant="destructive"
          onClick={handleDeleteGallery}
          isLoading={deleting}
        >
          Delete
        </Button>
      </div>
    </AdminGalleryStyles.GridItem>
  );
};

export default AdminGallery;
