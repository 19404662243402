import { EmailServices } from 'app/services';
import { RequestServicePayload } from 'app/services/email/types';
import { parseEmailJSError } from 'app/utils/parseError';
import { createContext, useState } from 'react';
import { toast } from 'react-toastify';
import { StatusType } from '../types';

interface RequestServiceContextProps {
  status: StatusType;
  submit: ((_: RequestServicePayload) => void) | null;
}

export type ProductType = 'SHM' | 'SHB' | 'SHP' | 'SHU';

const RequestServiceContext = createContext<RequestServiceContextProps>({
  status: 'idle',
  submit: null,
});

export const useRequestServiceContext = (): RequestServiceContextProps => {
  const [status, setStatus] = useState<RequestServiceContextProps['status']>(
    'idle'
  );

  return {
    status,
    submit: async (payload: RequestServicePayload) => {
      setStatus('submitting');
      try {
        await EmailServices.requestService(payload);
        setStatus('submitted');
        toast.success('Request sent successfully');
      } catch (error) {
        const errorMessage = parseEmailJSError(error);
        toast.error(errorMessage);
        setStatus('error');
      }
    },
  };
};

export default RequestServiceContext;
