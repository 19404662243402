import { css } from '@emotion/react';
import styled from '@emotion/styled';
import COLORS from 'app/constants/Colors';

import { convertPixelToRem } from 'app/utils/stylesHelpers';

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  height: max-content;
  row-gap: ${convertPixelToRem(10)};
`;

export interface TextAreaProps {
  error?: boolean;
}

const Element = styled.textarea<TextAreaProps>`
  border: solid 2px ${COLORS.blue};
  min-height: ${convertPixelToRem(100)};
  padding: ${convertPixelToRem(10)} ${convertPixelToRem(10)} 0
    ${convertPixelToRem(25)};
  ${(props) =>
    props.error &&
    css`
      border-color: ${COLORS.red100};
      outline-color: ${COLORS.red100};

      &::placeholder {
        color: ${COLORS.defaultWhite};
      }
    `}
`;

const TextAreaStyles = { Container, Element };

export default TextAreaStyles;
