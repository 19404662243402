import { useState } from 'react';

import Button from 'app/components/ui/Button';
import Text from 'app/components/ui/Text';
import academyData, { AcademyDataProps } from 'app/data/academy';
import ApplicationForm from './components/ApplicationForm';
import AcademyStyles from './styles';
import { Helmet } from 'react-helmet';

const Academy = () => {
  const [modalData, setmodalData] = useState<{
    isOpen: boolean;
    content: AcademyDataProps | null;
  }>({
    isOpen: false,
    content: null,
  });

  const handleModal = (content: AcademyDataProps) => {
    setmodalData({
      isOpen: true,
      content,
    });
  };

  const closeModal = () => setmodalData({ isOpen: false, content: null });

  return (
    <>
      <Helmet>
        <title>Academy | Sinnot Technologies</title>
      </Helmet>
      <AcademyStyles.Container className="pad">
        <header>
          <Text color="blue" weight={500} lineHeight={30}>
            Acquire knowledge on solar electrification, backup systems, and
            inverters. From energy auditing to energy management/efficiency,
            system design, applications and implementations. Practical and
            Theoretical sessions are all inclusive. SINNOT Solar-Inverter
            Academy Training is very interactive. We use our years of experience
            to guide you to excellence in the solar energy industry.
          </Text>
        </header>
        <AcademyStyles.CoursesContainer>
          <Text as="h3" weight={700} size={35} align="center" color="blue">
            Our Courses
          </Text>
          <AcademyStyles.Grid>
            {academyData.map(({ title, description, image }) => {
              return (
                <AcademyStyles.GridItem key={`${title}-${description}`}>
                  <div>
                    <img src={image} alt={title} />
                    <Text as="h3" weight={700}>
                      {title}
                    </Text>
                  </div>
                  <Text as="p" weight={400}>
                    {description}
                  </Text>
                  <Button
                    onClick={() => handleModal({ title, description, image })}
                  >
                    Apply
                  </Button>
                </AcademyStyles.GridItem>
              );
            })}
          </AcademyStyles.Grid>
        </AcademyStyles.CoursesContainer>
        {modalData.isOpen && (
          <ApplicationForm {...{ ...modalData, closeModal }} />
        )}
      </AcademyStyles.Container>
    </>
  );
};

export default Academy;
