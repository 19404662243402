import { useContext, useEffect } from 'react';

import LoaderBox from 'app/components/ui/LoaderBox';
import Text from 'app/components/ui/Text';
import ClientNewsContext from 'app/context/ClientNews/ClientNewsContext';
import usePagination from 'app/hooks/usePagination';
import { MainNewsStyles } from './styles';
import { convertHMTLToString } from 'app/utils/helpers';
import Button from 'app/components/ui/Button';
import Pagination from 'app/components/ui/Pagination';
import Timeline from './components/TimeLine';
import AsideContent from './components/AsideContent';
import { useHistory } from 'react-router-dom';
import share from 'app/utils/share';
import useCopy from 'app/hooks/useCopy';
import useComponentDidUpdate from 'app/hooks/useComponentDidUpdate';
import { toast } from 'react-toastify';

const ClientNews = () => {
  const contextValues = useContext(ClientNewsContext);
  const {
    currentDisplayedData,
    numberOfPages,
    handlePageChange,
    currentPage,
  } = usePagination(contextValues.data || {});
  const history = useHistory();
  const { copy, copied } = useCopy('');

  const navigateToClickedNews = (id: string) => {
    history.push(`/news/${id}`);
  };

  useEffect(() => {
    contextValues.fetchNews({ endAt: '5', startAt: '1' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useComponentDidUpdate(() => {
    if (copied) {
      toast.success('Content copied to clipboard');
    }
  }, [copied]);

  if (contextValues.status === 'fetching' || !contextValues.data)
    return <LoaderBox height={200} />;

  if (!Object.values(contextValues.data).length) return <p> No news found </p>;

  return (
    <>
      <MainNewsStyles.Container className="pad">
        <MainNewsStyles.News>
          {Object.values(currentDisplayedData || {}).map((news) => {
            const shareContent = `${news.title} \n\n ${convertHMTLToString(
              news.content,
              200
            )} \n\n `;
            const url = `https://sinnot.ng/news/${news.id}`;
            const handleClick = () => {
              share({
                title: news.title,
                text: shareContent,
                url,
                onFailure: () => {
                  copy(`${shareContent} ${url}`);
                },
              });
            };
            return (
              <MainNewsStyles.EachNews key={news.id}>
                <Text as="h1" size={30}>
                  {news.title}
                </Text>
                <Timeline date={news.updated_at} />
                <img
                  src={news.previewImage}
                  alt={news.title}
                  title={news.title}
                />
                <Text> {convertHMTLToString(news.content)} </Text>

                <MainNewsStyles.ActionsContainer>
                  <Button
                    variant="tertiary"
                    onClick={() => navigateToClickedNews(news.id)}
                  >
                    Read More
                  </Button>
                  <Button variant="secondary" onClick={handleClick}>
                    Share
                  </Button>
                </MainNewsStyles.ActionsContainer>
              </MainNewsStyles.EachNews>
            );
          })}
        </MainNewsStyles.News>
        <AsideContent />
      </MainNewsStyles.Container>
      <Pagination
        pageCount={numberOfPages}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        className="pagination"
        onPageChange={handlePageChange}
        forcePage={currentPage}
      />
    </>
  );
};

export default ClientNews;
