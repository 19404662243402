import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';

import Colors from 'app/constants/Colors';
import HelmetImage from 'app/assets/images/helmet.png';
import SolarImage from 'app/assets/images/solar-bg.png';
import Panelimage from 'app/assets/images/panel.png';
import { RouteURLs } from 'app/routes/Routes';
import { convertPixelToRem } from 'app/utils/stylesHelpers';
import media from 'app/utils/media';

interface NavBarContainerProps {
  isSideBardOpen: boolean;
}

const NavBarContainer = styled.nav<NavBarContainerProps>`
  display: flex;
  justify-content: space-between;
  column-gap: ${convertPixelToRem(200)};
  align-items: center;
  padding: 0 5%;
  position: fixed;
  width: 100%;
  top: 0;
  height: ${convertPixelToRem(75)};

  transition: background-color 500ms ease-in;

  ${media.bigTablet`
    column-gap: 80px;
    width : 100%;
    padding-left : 10px;
    padding-right : 10px;
   
  `}

  .logo {
    height: ${convertPixelToRem(50)};
    width: ${convertPixelToRem(50)};
  }

  .bar {
    display: none;
    border: none;
    background: transparent;

    .element {
      width: ${convertPixelToRem(30)};
      height: ${convertPixelToRem(30)};
      fill: ${Colors.white};
    }

    ${media.bigTablet`
      display : block
    `}
  }

  .links {
    display: flex;
    grid-auto-flow: column;
    column-gap: ${convertPixelToRem(50)};
    align-items: center;

    ${media.bigTablet`
      column-gap: 20px;
    `}

    ${media.bigTablet`
      position: fixed;
      right: 0;
      display: grid;
      grid-auto-flow: row;
      top: 0;
      align-items: flex-start;
      row-gap: 20px;
      height: 100%;
      align-content: baseline;
      background: rgba(0,0,0,0.5);
      min-width : 250px;
      padding : 10px 0 0 0;
      transition : transform 400ms ease-in;

      a{
        padding : 5px 0 5px 30px;
      }

      button{
        margin-left : 30px;
      }
    `}

    ${(props) => {
      return props.isSideBardOpen
        ? css`
            ${media.bigTablet`
      transform : translateX(0);
    `}
          `
        : css`
            ${media.bigTablet`
            transform : translateX(100%);

    `}
          `;
    }}
  }
`;

const NavBarLink = styled(NavLink)`
  color: ${Colors.white};

  &.active-link {
    color: ${Colors.yellow};
  }
`;

interface HeaderContainerProps {
  url: RouteURLs;
  isNavBarColorVisible: boolean;
}

const HeaderContainer = styled.header<HeaderContainerProps>`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;

  ${(props) =>
    props.isNavBarColorVisible &&
    css`
      nav {
        background-color: rgba(0, 67, 180, 0.8);
      }
    `}

  .content {
    p {
      font-size: ${convertPixelToRem(30)};
      color: ${Colors.white};
    }

    h1 {
      font-size: ${convertPixelToRem(120)};
      color: ${Colors.yellow};
      font-weight: 700;
    }
  }

  ${(props) => {
    switch (props.url) {
      case '/home':
        return css`
          display: flex;
          flex-direction: column;
          min-height: 100vh;
          background-image: linear-gradient(
              ${Colors.blue100},
              ${Colors.blue100}
            ),
            url(${SolarImage});

          .content {
            display: flex;
            flex-direction: column;
            row-gap: ${convertPixelToRem(30)};
            flex-grow: 1;
            align-items: center;
            justify-content: center;
            text-align: center;
          }

          .footer {
            display: flex;
            justify-content: space-around;
            height: ${convertPixelToRem(70)};
            background-color: rgba(0, 0, 0, 0.37);
            justify-self: flex-end;

            .icon {
              height: ${convertPixelToRem(30)};
              width: ${convertPixelToRem(30)};

              fill: ${Colors.yellow};
            }
          }
        `;

      default:
        return css`
          min-height: 75vh;
          background-image: linear-gradient(
              ${Colors.blue100},
              ${Colors.blue100}
            ),
            url(${HelmetImage});

          display: flex;
          flex-direction: column;

          .content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            flex-wrap: wrap;
            align-content: center;

            ${media.tablet`
              padding: 0 10px;

              h1{
                font-size : 90px;
              }
            `}
          }
        `;
    }
  }}
`;

const BodyContainer = styled.main`
  .pad {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;

    ${media.laptop`
      padding-left: 10%;
      padding-right: 10%;
    `}

    ${media.bigTablet`
      padding-left : 10px;
      padding-right : 10px;
    `}
  }
`;

const FooterContainer = styled.footer`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-image: linear-gradient(rgba(2, 18, 45, 0.9), rgba(2, 18, 45, 0.9)),
    url(${Panelimage});
  background-size: cover;
  padding: ${convertPixelToRem(50)} ${convertPixelToRem(100)}
    ${convertPixelToRem(50)} ${convertPixelToRem(110)};

  ${media.bigTablet`
  row-gap : 20px;
    >div{
      width : 100%;
    }
  `}

  ${media.phone`
    padding-left : 20px;
    padding-right : 20px;
  `}

  .details {
    display: flex;
    column-gap: ${convertPixelToRem(15)};
    .title {
      writing-mode: vertical-lr;
      text-orientation: upright;
      font-weight: 700;
      color: ${Colors.yellow100};
      align-self: center;
      height: max-content;
    }

    .left-bar {
      border-left: solid 1.5px ${Colors.yellow100};
      padding-left: ${convertPixelToRem(15)};
      display: grid;
      row-gap: ${convertPixelToRem(10)};

      * {
        color: ${Colors.white};
      }
    }
    .right-bar {
      border-right: solid 1.5px ${Colors.yellow100};
      padding-right: ${convertPixelToRem(15)};
      display: grid;
      row-gap: ${convertPixelToRem(10)};
      * {
        color: ${Colors.white};
      }

      ${media.bigTablet`
        border : none;
          & ~ .title{
            display : none
          }
        `}
    }
  }
`;

const FooterLogoContainer = styled.section`
  display: grid;
  row-gap: ${convertPixelToRem(10)};
  align-items: center;
  place-items: center;

  ${media.bigTablet`
    display : none;
  `}

  * {
    text-align: center;
    color: ${Colors.yellow};
  }

  img {
    width: ${convertPixelToRem(150)};
    height: ${convertPixelToRem(150)};
  }
`;

export const NavBarStyles = {
  Container: NavBarContainer,
  Link: NavBarLink,
};

export const HeaderStyles = {
  Container: HeaderContainer,
};

export const FooterStyles = {
  Container: FooterContainer,
  LogoContainer: FooterLogoContainer,
};

export const BodyStyles = {
  Container: BodyContainer,
};
