import { FirebaseServices } from 'app/services';

import { createContext, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { ClientGalleryContextProps } from './types';

const ClientGalleryContext = createContext<ClientGalleryContextProps>({
  status: 'idle',
  data: null,
  fetchGallery: () => {},
});

export const useClientGalleryContext = (): ClientGalleryContextProps => {
  const [status, setStatus] = useState<ClientGalleryContextProps['status']>(
    'idle'
  );
  const [data, setData] = useState<ClientGalleryContextProps['data']>(null);

  const fetchGallery = useCallback(async () => {
    try {
      setStatus('fetching');
      const res = await FirebaseServices.fetchGallery();
      const stateData: { [key: string]: any } = {};
      res.forEach((doc) => {
        stateData[doc.id] = doc.data();
      });
      setData(stateData);
      setStatus('fetched');
    } catch (error) {
      toast.error(error.message);
      setStatus('error');
    }
  }, []);

  return {
    status,
    data,
    fetchGallery,
  };
};

export default ClientGalleryContext;
