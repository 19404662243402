import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import 'react-quill/dist/quill.snow.css';

import Button from 'app/components/ui/Button';
import Input from 'app/components/ui/Input';
import Text from 'app/components/ui/Text';

import { ProcessNewsStyles } from './styles';
import { UploadAdminNewsPayload } from 'app/context/Admin/News/types';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { getFileSizeInKB } from 'app/utils/helpers';
import { toast } from 'react-toastify';
import AdminNewsContext from 'app/context/Admin/News/AdminNewsContext';
import { useHistory, useParams } from 'react-router-dom';
import LoaderBox from 'app/components/ui/LoaderBox';

const editorFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'indent',
  'image',
  'color',
  'font',
];
const editorModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],

    [{ color: [] }],
    [{ font: [] }],
    ['image'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

type FormData = UploadAdminNewsPayload;

const Schema = yup.object().shape({
  title: yup.string().required('Kindly input news title'),
  content: yup
    .string()
    .required('Kindly input news content')
    .min(20, 'News content cannot be less than 20 characters '),
  previewImage: yup.string().required('Kindly upload news preview image'),
});

const ProcessNews = () => {
  const params = useParams<{ id?: string }>();
  const history = useHistory();
  const contextValues = useContext(AdminNewsContext);
  const {
    control,
    handleSubmit,
    errors,
    setValue,
    setError,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      content: '',
      previewImage: '',
      title: '',
    },
    resolver: yupResolver(Schema),
  });
  const [file, setFile] = useState<File | null>();

  const handleImageChange = (event: ChangeEvent<any>) => {
    const files = event.target.files as FileList;
    const MAX_SIZE = 1000;
    if (getFileSizeInKB(files[0].size) > MAX_SIZE) {
      setError('previewImage', {
        message: ` Image ${files[0].name} is too large. Upload a file with a size less than 1mb`,
      });
      toast.error(
        ` Image ${files[0].name} is too large. Upload a file with a size less than 1mb`
      );
    } else {
      setValue('previewImage', 'uploaded', { shouldValidate: true });
      setFile(files[0]);
    }
  };

  const submit = ({ previewImage: _, ...data }: FormData) => {
    if (params?.id) {
      return contextValues.editNews({
        previewImage: file || undefined,
        id: params.id,
        ...data,
      });
    }
    file && contextValues.addNews({ previewImage: file, ...data });
  };

  useEffect(() => {
    if (contextValues.status === 'submitted') {
      reset();
      history.push('/admin/news');
    }

    if (
      contextValues.status === 'fetched' &&
      params?.id &&
      contextValues.data?.[params.id]
    ) {
      const corresponginData = contextValues.data[params.id];
      setValue('title', corresponginData.title);
      setValue('content', corresponginData.content);
      setValue('previewImage', 'uploaded');
    }
  }, [
    contextValues.status,
    reset,
    params.id,
    contextValues.data,
    setValue,
    history,
  ]);

  useEffect(() => {
    if (params?.id) {
      contextValues.fetchSingleNews({ id: params.id });
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, reset]);

  if (contextValues.status === 'fetching') return <LoaderBox />;

  return (
    <ProcessNewsStyles.Container onSubmit={handleSubmit(submit)}>
      <Controller
        as={
          <Input
            label="Title"
            placeholder="E.g The effect of solar energy in the society"
            errorMessage={errors.title?.message}
          />
        }
        control={control}
        name="title"
      />
      <Controller
        render={() => (
          <Input
            type="file"
            label="Preview Image"
            errorMessage={errors.previewImage?.message}
            onChange={handleImageChange}
          />
        )}
        control={control}
        name="previewImage"
      />
      <ProcessNewsStyles.EditorContainer>
        <Text> News Content </Text>
        <Controller
          render={({ onChange, value, name }) => {
            return (
              <ProcessNewsStyles.Editor
                theme="snow"
                modules={editorModules}
                formats={editorFormats}
                value={value}
                onChange={onChange}
              />
            );
          }}
          control={control}
          name="content"
        />
        {errors.content?.message && (
          <Text color="red200"> {errors.content.message} </Text>
        )}
      </ProcessNewsStyles.EditorContainer>
      <Button
        variant="secondary"
        type="submit"
        isLoading={
          contextValues.status === 'submitting' ||
          contextValues.status === 'editing'
        }
      >
        {params.id ? 'Edit' : 'Publish'}
      </Button>
    </ProcessNewsStyles.Container>
  );
};

export default ProcessNews;
