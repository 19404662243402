import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { convertPixelToRem } from 'app/utils/stylesHelpers';
import Colors from 'app/constants/Colors';
import { Link } from 'react-router-dom';
import media from 'app/utils/media';

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  column-gap: ${convertPixelToRem(30)};
  padding-top: ${convertPixelToRem(50)};
  padding-bottom: ${convertPixelToRem(50)};
`;

const News = styled.section`
  display: grid;
  row-gap: ${convertPixelToRem(70)};
  flex: 60%;
  ${media.bigTablet`
    flex : 100%;
  `}
`;

const ActionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: ${convertPixelToRem(10)};
  max-width: max-content;
`;

const timeline = css`
  display: flex;
  justify-content: space-between;
  column-gap: ${convertPixelToRem(5)};
  align-items: center;

  span {
    color: ${Colors.blue};
    align-items: center;
    column-gap: ${convertPixelToRem(9)};
    display: grid;
    grid-auto-flow: column;
  }

  svg {
    width: ${convertPixelToRem(20)};
    height: ${convertPixelToRem(20)};
    fill: ${Colors.blue};
  }
`;

const EachNews = styled.article`
  display: grid;
  width: 100%;
  max-width: ${convertPixelToRem(500)};
  row-gap: ${convertPixelToRem(20)};

  .timeline {
    ${timeline}
    span {
      font-size: ${convertPixelToRem(20)};
    }
  }

  img {
    max-width: ${convertPixelToRem(550)};
    object-fit: contain;
    width: 100%;
  }

  button {
    border-width: ${convertPixelToRem(2)};
    font-weight: bolder;
  }

  ${media.bigTablet`
    padding: 0 20px;
    max-width : 600px;
  `}
`;

const NewsAside = styled.aside`
  display: grid;
  row-gap: ${convertPixelToRem(40)};
  height: max-content;
  flex: 40%;

  .search {
    display: none;
  }

  ${media.bigTablet`
    display : none;
  `}
`;
const NewsAsideRecentNewsContainer = styled.div`
  display: grid;
  row-gap: ${convertPixelToRem(25)};
  padding-top: ${convertPixelToRem(20)};
`;

const NewsAsideRecentNews = styled(Link)`
  display: grid;
  grid-template-areas: 'image title' 'image timeline';
  column-gap: ${convertPixelToRem(20)};
  img {
    grid-area: image;
    height: ${convertPixelToRem(100)};
    width: ${convertPixelToRem(100)};
    object-fit: cover;
  }

  .title {
    grid-area: title;
    max-width: auto;
  }

  .timeline {
    ${timeline};
    grid-area: timeline;
    column-gap: ${convertPixelToRem(10)};
    align-items: center;
    span {
      font-size: ${convertPixelToRem(16)};
      column-gap: ${convertPixelToRem(5)};
    }
    svg {
      width: ${convertPixelToRem(16)};
      height: ${convertPixelToRem(16)};
    }
  }
`;

const GoBack = styled(Link)`
  display: grid;
  grid-auto-flow: column;
  width: max-content;
  column-gap: ${convertPixelToRem(5)};
  align-items: center;

  svg {
    width: ${convertPixelToRem(30)};
    height: ${convertPixelToRem(40)};
  }

  * {
    fill: ${Colors.blue};
  }

  ${media.bigTablet`
    padding : 0 20px;
  `}
`;

const TimeLine = styled.div``;

export const MainNewsStyles = {
  Container,
  News,
  EachNews,
  TimeLine,
  GoBack,
  ActionsContainer,
};

export const NewsAsideStyles = {
  NewsAside,
  NewsAsideRecentNewsContainer,
  NewsAsideRecentNews,
  TimeLine,
};
