import { encryptString, replaceWhitespaceWithDash } from 'app/utils/helpers';
import firebase from 'firebase';

import {
  AddNewsPayload,
  DeleteGalleryPayload,
  DeleteNewsPayload,
  EditNewsPayload,
  FetchNewsPayload,
  FetchSingleNewsPayload,
  LoginPayload,
  UploadGalleryPayload,
} from './types';

const login = async (payload: LoginPayload) => {
  return await firebase
    .auth()
    .signInWithEmailAndPassword(payload.email, payload.password);
};

const logout = async () => {
  return await firebase.auth().signOut();
};

const signUp = async (payload: LoginPayload) => {
  await firebase
    .auth()
    .createUserWithEmailAndPassword(payload.email, payload.password);
};

const getIdToken = async () => {
  return await firebase.auth().currentUser?.getIdToken();
};

const fetchNews = async (payload: FetchNewsPayload) => {
  return await firebase
    .firestore()
    .collection('news')
    .orderBy('updated_at', 'desc')
    .get();
};

const addNews = async ({ previewImage, ...payload }: AddNewsPayload) => {
  const currentDate = Date.now();
  const uniqueId = replaceWhitespaceWithDash(payload.title);

  return new Promise(async (resolve, reject) => {
    await firebase
      .storage()
      .ref(`/news-preview-images/${uniqueId}`)
      .put(previewImage)
      .then(({ ref }) => {
        ref
          .getDownloadURL()
          .then(async (url) => {
            await firebase
              .firestore()
              .collection('news')
              .doc(uniqueId)
              .set({
                ...payload,
                created_at: currentDate,
                updated_at: currentDate,
                id: uniqueId,
                previewImage: url,
              })
              .then(() => resolve(true))
              .catch((err) => reject(err));
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};

const editNews = async ({ previewImage, ...payload }: EditNewsPayload) => {
  const currentDate = Date.now();
  firebase
    .firestore()
    .collection('news')
    .doc(payload.id)
    .update({
      ...payload,
      updated_at: currentDate,
    });

  if (previewImage) {
    return await firebase
      .storage()
      .ref(`/news-preview-images/${payload.id}`)
      .put(previewImage);
  }
};

const fetchSingleNews = async (payload: FetchSingleNewsPayload) => {
  const res = await firebase
    .firestore()
    .collection('news')
    .doc(payload.id)
    .get();

  if (res.exists) return res.data();

  return null;
};

const deleteNews = async (payload: DeleteNewsPayload) => {
  return await firebase.firestore().collection('news').doc(payload.id).delete();
};

const fetchGallery = async () => {
  return await firebase.firestore().collection('gallery').get();
};

const uploadGallery = ({ image, ...payload }: UploadGalleryPayload) => {
  const currentDate = Date.now();
  const id = encryptString(`${currentDate}-${payload.caption}`);
  return new Promise(async (resolve, reject) => {
    await firebase
      .storage()
      .ref(`/gallery/${id}`)
      .put(image)
      .then(({ ref }) => {
        ref
          .getDownloadURL()
          .then(async (url) => {
            await firebase
              .firestore()
              .collection('gallery')
              .doc(id)
              .set({
                ...payload,
                created_at: currentDate,
                updated_at: currentDate,
                imageURL: url,
                id,
              })
              .then(() => resolve(true))
              .catch((err) => reject(err));
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};

const deleteGallery = async (payload: DeleteGalleryPayload) => {
  return new Promise(async (resolve, reject) => {
    await firebase
      .storage()
      .ref(`/gallery/${payload.id}`)
      .delete()
      .then(async () => {
        await firebase
          .firestore()
          .collection('gallery')
          .doc(payload.id)
          .delete()
          .then(() => resolve(true))
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};

const FirebaseServices = {
  login,
  signUp,
  getIdToken,
  fetchNews,
  addNews,
  fetchSingleNews,
  editNews,
  deleteNews,
  fetchGallery,
  uploadGallery,
  deleteGallery,
  logout,
};

export default FirebaseServices;
