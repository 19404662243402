import styled from '@emotion/styled';
import Colors from 'app/constants/Colors';
import { convertPixelToRem } from 'app/utils/stylesHelpers';

const Container = styled.div`
  * {
    list-style-type: none;
    outline: none;
  }

  .container {
    display: grid;
    grid-auto-flow: column;
    column-gap: ${convertPixelToRem(10)};
    width: max-content;
    margin: auto;
    align-items: center;
    padding: ${convertPixelToRem(20)} 0 ${convertPixelToRem(30)};
  }

  .page {
    border: solid ${convertPixelToRem(1)} ${Colors.blue};
    background-color: ${Colors.white};
    color: ${Colors.blue};
    width: ${convertPixelToRem(30)};
    height: ${convertPixelToRem(30)};

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .active {
    border: none;
    background-color: ${Colors.blue};

    * {
      color: ${Colors.white};
    }
  }

  .nav-button {
    border: none;
    background: transparent;
    &:hover {
      cursor: pointer;
    }

    &--previous {
      a {
        &::before {
          content: '<<< ';
        }
      }
    }

    &--next {
      a {
        &::after {
          content: ' >>>';
        }
      }
    }
  }
`;

const PaginationStyles = {
  Container,
};

export default PaginationStyles;
