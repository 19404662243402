import React, { InputHTMLAttributes, FC } from 'react';

import Colors from 'app/constants/Colors';
import Text from '../Text';
import InputStyles from './styles';

interface Props extends InputHTMLAttributes<any> {
  label?: string;
  errorMessage?: string;
}

const Input: FC<Props> = React.forwardRef(
  ({ label, errorMessage, className, required, ...rest }, ref) => {
    return (
      <InputStyles.InputContainer className={className}>
        {label && (
          <Text as="label">
            {label} {required && <Text color="red200"> * </Text>}
          </Text>
        )}
        <InputStyles.InputElement
          {...{ ...rest }}
          // @ts-ignore
          ref={ref}
          // @ts-ignore
          error={errorMessage}
        />
        {errorMessage && (
          <Text color={Colors.red200} as="span" size={14}>
            {errorMessage}
          </Text>
        )}
      </InputStyles.InputContainer>
    );
  }
);

export default Input;
