import ClientNewsContext, {
  useClientNewsContext,
} from 'app/context/ClientNews/ClientNewsContext';
import { Helmet } from 'react-helmet';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ClientNews from './ClientNews';
import ClientNewsContent from './ClientNewsContent';

const ClientNewsRoute = () => {
  const { url } = useRouteMatch();
  const contextValues = useClientNewsContext();

  return (
    <>
      <Helmet> News | Sinnot Technologies </Helmet>
      <ClientNewsContext.Provider value={contextValues}>
        <Switch>
          <Route path={`${url}`} exact component={ClientNews} />
          <Route path={`${url}/:id`} exact component={ClientNewsContent} />
        </Switch>
      </ClientNewsContext.Provider>
    </>
  );
};

export default ClientNewsRoute;
