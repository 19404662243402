import { ReactComponent as CalendarIcon } from 'app/assets/icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'app/assets/icons/clock.svg';
import { ReactComponent as AdminIcon } from 'app/assets/icons/user.svg';
import Text from 'app/components/ui/Text';
import { format } from 'date-fns';
import { FC } from 'react';
import { MainNewsStyles } from '../styles';

interface TimelineProps {
  date: number;
}

const Timeline: FC<TimelineProps> = ({ date }) => {
  const readableDate = format(date, 'MMM. d, yyyy');
  const readableTime = format(date, 'hh:mm aaaa');

  return (
    <MainNewsStyles.TimeLine className="timeline">
      <Text>
        <CalendarIcon /> {readableDate}
      </Text>
      <Text>
        <ClockIcon /> {readableTime}
      </Text>
      <Text>
        <AdminIcon /> Admin
      </Text>
    </MainNewsStyles.TimeLine>
  );
};

export default Timeline;
