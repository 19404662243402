import firebase from 'firebase';
import { Global } from '@emotion/react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import Overall from 'app/components/containers/Overall/Overall';
import globalStyles from 'app/constants/globalStyles';
import ScrollToTop from 'app/components/ui/ScrollToTop';
import { routes } from 'app/routes';
import { AdminOverall } from 'app/pages/Admin';
import GeneralAdmin from 'app/components/containers/GeneralAdmin';

interface FirebaseConfigType {
  apiKey: string | undefined;
  authDomain: string | undefined;
  projectId: string | undefined;
  storageBucket: string | undefined;
  messagingSenderId: string | undefined;
  appId: string | undefined;
  measurementId: string | undefined;
  databaseURL: string | undefined;
}

const firebaseConfig: FirebaseConfigType = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUERMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DATABASE_URL,
};

firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      <Global styles={globalStyles} />

      <Switch>
        <Route path="/admin">
          <Route path="/admin" exact>
            <Redirect to="/admin/login" />
          </Route>
          <AdminOverall>
            <Switch>
              {routes.adminAuthRoutes.map(({ id, ...rest }) => {
                return <Route key={id} {...rest} />;
              })}
              <Route path="/admin">
                <GeneralAdmin>
                  <Switch>
                    {routes.adminRoutes.map(({ id, ...rest }) => {
                      return <Route key={id} {...rest} />;
                    })}
                  </Switch>
                </GeneralAdmin>
              </Route>
            </Switch>
          </AdminOverall>
        </Route>
        <Route path="/">
          <Overall>
            <Switch>
              <Route path="/" exact>
                <Redirect to="/home" />
              </Route>
              {routes.guestRoutes.map(({ id, ...rest }) => {
                return <Route key={id} {...rest} />;
              })}
              {routes.guestRoutesNotRenderedOnNavBar.map(({ id, ...rest }) => {
                return <Route key={id} {...rest} />;
              })}
            </Switch>
          </Overall>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
