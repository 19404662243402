import { Link } from 'react-router-dom';

import Text from 'app/components/ui/Text';
import Logo from 'app/assets/images/logo.png';
import { routes } from 'app/routes';
import { FooterStyles } from './styles';

const contackDetails: Array<{ title: string; description: string }> = [
  {
    title: 'Flat 2, Behind IBI Petrol',
    description: 'Station, Roadblock, Akure',
  },
  { title: '198, Ikorodu Road,', description: 'Palmgroove, Lagos' },
  { title: '+2349155225512', description: '+2348068118888' },
  {
    title: 'info@sinnot.ng',
    description: '',
  },
];

const Footer = () => {
  return (
    <FooterStyles.Container>
      <div className="details">
        <Text className="title"> QUICK LINKS </Text>
        <div className="left-bar">
          {routes.guestRoutes.map(({ id, title, path }) => {
            return (
              <Link key={`${id}-footer`} to={path}>
                {title}
              </Link>
            );
          })}
        </div>
      </div>
      <FooterStyles.LogoContainer>
        <img src={Logo} alt="Sinnot technologies" />
        <Text> Solving Energy Poverty in Africa </Text>
      </FooterStyles.LogoContainer>
      <div className="details">
        <div className="right-bar">
          {contackDetails.map(({ title, description }) => {
            return (
              <section key={title}>
                <Text> {title} </Text> <br />
                <Text> {description} </Text>
              </section>
            );
          })}
        </div>
        <Text className="title"> CONTACT US</Text>
      </div>
    </FooterStyles.Container>
  );
};

export default Footer;
